export const BASENAME = '';

export const ROOT = BASENAME+'/';
export const HOME = BASENAME+'/home';
export const ABOUT = BASENAME+'/about';
export const SERVICES = BASENAME+'/services';
export const SERVICE_DETAILS = BASENAME+'/servicesDetails';
export const PORTFOLIO = BASENAME+'/portfolio';
export const PORTFOLIO_DETAILS = BASENAME+'/portfolioDetails';
export const TEAM = BASENAME+'/team';
export const TEAM_DETAILS = BASENAME+'/teamDetails';
export const BLOG = BASENAME+'/blog';
export const BLOG_DETAILS = BASENAME+'/blogDetails';
export const CONTACT = BASENAME+'/contact';
export const VIDEO = {
    SELF: BASENAME+'/session',
    HOME: BASENAME+'/session-start',
    CALL: BASENAME+'/session-call'
};
export const SIGN_UP = BASENAME+'/sign-up';
export const SIGN_IN = BASENAME+'/sign-in';
export const PRINCING = BASENAME+'/pricing';

export const SSO = {
    LOGIN: BASENAME+'/sso/login',
    REGISTER: BASENAME+'/sso/registration'
};

export const DASHBOARD = {
    SELF: '/dashboard',
    PLAN: '/dashboard/plan',
    PROFILE: '/dashboard/app/profile/view',
    SESSIONS: '/dashboard/app/sessions/list',
    TRANSACTIONS: '/dashboard/transactions',
    SUBSCRIPTIONS: '/dashboard/subscriptions',
    OVERVIEW: '/dashboard/overview',
    CONTACT: '/dashboard/contacts'
}