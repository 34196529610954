import './leave.scss';
import classNames from 'classnames';
import { Button, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

interface LeaveButtonProps {
  onExitClick?: () => void;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onExitClick } = props;
  return (
    <div className={classNames('camera-footer')}>
      <Tooltip
        title={'leave the meeting'}
      >
        <Button
          className={'leave-button bg-transparent'}
          icon={<LogoutOutlined />}
          // eslint-disable-next-line react/jsx-boolean-value
          ghost={true}
          shape="circle"
          size="large"
          onClick={onExitClick}
        />
      </Tooltip>
      <div style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                marginLeft: "30px"
              }}>
        <span>Leave</span>
      </div>
    </div>
  );
};

export default LeaveButton;
