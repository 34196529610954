import './chat.scss';
import 'antd/dist/antd.css';
import { useState } from 'react';
import classNames from 'classnames';
import { FormOutlined } from '@ant-design/icons';
import { Tooltip, Button, Drawer, Input  } from 'antd';

const { TextArea } = Input;

const NoteButton = (props: any) => {
  const { className, treatNote } = props;
  const [note, setNote] = useState('');
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const meetingNotes = (e:any) => {
    setNote(e.target.value);
    treatNote(note)
  }
  return (
    <div className={classNames('chat-footer', className)}>
      <Tooltip title={`open personal notes`}>
        <Button
          className={classNames('chat-button', className)}
          icon={
            <FormOutlined />
          }
          ghost={true}
          shape="circle"
          size="large"
          onClick={() => {
            if(visible) {
              onClose();
            } else {
              showDrawer();
            }
          }}
        />
      </Tooltip>
      <Drawer 
        title="Personal notes" 
        placement="right" 
        mask={false}
        onClose={onClose} visible={visible}
      >
        <div className="chat-message-wrap">
          <TextArea
            onChange={(e) => meetingNotes(e)}
            value={note}
            rows={20}
            style={{ borderColor: 'black' }}
            placeholder="Type message here ..."
          />
        </div>
      </Drawer>
      <div style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                marginLeft: "30px"
              }}>
        <span>Notes</span>
      </div>
    </div>
  );
};
export default NoteButton;
