import produce from "immer";
import { message } from "antd";
import VideoFrame from "./frame";
import {connect} from 'react-redux';
import * as Urls from '../../utils/routes';
import UserInfoModal from "./UserInfoModal";
import SessionService from "../../services/sessions";
import MeetingService from "../../services/meetings";
import ZoomContext from "../../context/zoom-context";
import ChatContext from "../../context/chat-context";
import CommandContext from "../../context/cmd-context";
import LoadingLayer from "../../components/loading-layer";
import ZoomMediaContext from "../../context/media-context";
import ZoomVideo, { ConnectionState } from "@zoom/videosdk";
import { formatDate, nowTimestamp } from "../../utils/utils";
import { setMeetingName } from "../../actions/MeetingActions";
import RecordingContext from "../../context/recording-context";
import { useEffect, useContext, useState, useCallback, useReducer } from "react";
import { ChatClient, CommandChannelClient, MediaStream, RecordingClient } from "../../index-types";

interface AppProps {
  meetingArgs: {
    signature: string;
    topic: string;
    name: string;
    password?: string;
  },
  authUser: any,
  setMeetingName: any,
  meetingData: any
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};

const THANK_YOU_PAGE = "https://immersively.care/session-thankyou";
const LIB_RESSOURCES = window.location.origin.includes("localhost") ? 
  `${window.location.origin}/lib` : 
  `${window.location.origin}/app/lib`;

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case "audio-encode": {
      draft.audio.encode = action.payload;
      break;
    }
    case "audio-decode": {
      draft.audio.decode = action.payload;
      break;
    }
    case "video-encode": {
      draft.video.encode = action.payload;
      break;
    }
    case "video-decode": {
      draft.video.decode = action.payload;
      break;
    }
    case "share-encode": {
      draft.share.encode = action.payload;
      break;
    }
    case "share-decode": {
      draft.share.decode = action.payload;
      break;
    }
    default:
      break;
  }
}, mediaShape);

function VideoCall(props: AppProps) {

  const [name, setName] = useState(props.meetingData ? props.meetingData.name : 'Undefined');
  const {
    meetingArgs: { signature, topic, password }
  } = props;

  const zmClient = useContext(ZoomContext);
  const [loading, setIsLoading] = useState(true);
  const [meeting, setMeeting] = useState<any>(null);
  const [loadingText, setLoadingText] = useState("");
  const [status, setStatus] = useState<string>("closed");
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [meetingSummary, setMeetingSummary] = useState<any>({});
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [sendUserInfoLoading, setSendUserInfoLoading] = useState(false);
  const [chatClient, setChatClient] = useState<ChatClient | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [recordingClient, setRecordingClient] = useState<RecordingClient | null>(null);
  const [commandClient, setCommandClient] = useState<CommandChannelClient | null>(null);

  useEffect(() => {
    if(!name || name === 'Undefined')
      getName();

    if(topic) {
      getMeeting();
    }
  }, [topic, name]);

  useEffect(() => {
    if(meeting?.id && meeting?.user) {
      
      localStorage.removeItem("transcription");
      localStorage.removeItem("heartbeat");
      localStorage.removeItem("wellbeing");
      localStorage.removeItem("attention");
      localStorage.removeItem("emotion");
      localStorage.removeItem("affect");
      localStorage.removeItem("engagement");
      localStorage.removeItem("pleasantness");
      localStorage.removeItem("age");

      const init = async () => {
        await zmClient.init("en-US", LIB_RESSOURCES, { webEndpoint: 'zoom.us' });
        try {
          setLoadingText("Joining the meeting... ");
          console.log("There 1");
          console.log(zmClient);
          await zmClient.join(topic, signature, props.meetingData.name, password).then(async () => {
            console.log("There 1.1");
            const stream = zmClient.getMediaStream();
            if(zmClient.getAllUser().length > 1) {
              // console.log("There 3");
              alert("Meeting is already in progress, please contact us if you did not start it.");
              await zmClient.leave().then(() => {
                // console.log("There 3");
                window.location.href = Urls.DASHBOARD.SESSIONS;
              });
            }
            setMediaStream(stream);
            const chatClient = zmClient.getChatClient();
            const commandClient = zmClient.getCommandClient();
            const recordingClient = zmClient.getRecordingClient();
            setChatClient(chatClient);
            setCommandClient(commandClient);
            setRecordingClient(recordingClient);
            setIsLoading(false);
          }).catch(async (err) => {
            console.log("There 4");
            console.log(err);
            //await zmClient.leave(true);
            //window.location.href = Urls.DASHBOARD.SESSIONS;
          });

        } catch (e: any) {
          console.log('Exception while joining...');
          setIsLoading(false);
          alert(e.reason);
        }
      };

      if (props.authUser) {
        if(props.authUser.id == meeting?.user?.id) {
          init();
        } else {
          onLeaveOrJoinSession();
        }
      } else {
        onLeaveOrJoinSession();
      }

      return () => {
        ZoomVideo.destroyClient();
      };
    }
  }, [signature, zmClient, password, meeting, props.authUser]);

  const getMeeting = () => {
    MeetingService.getMeetingByTopic(topic).then((response:any) => {
      if (response.endAt < nowTimestamp()) {
        alert("Meeting was scheduled for "+ formatDate(response.startAt));  
        window.location.href = props.authUser ? Urls.DASHBOARD.SESSIONS : THANK_YOU_PAGE;
      } else {
        setMeeting(response);
      }
    }).catch(async err => {
      console.log("Error meeting...");
      await zmClient.leave(zmClient.isHost());
      window.location.href = props.authUser ? Urls.DASHBOARD.SESSIONS : THANK_YOU_PAGE;
      console.log(err);
    })
  }

  const getName = () => {
    let username = prompt("Please enter your name");
    if (username !== null) {
      props.setMeetingName(username);
      setName(username);
      return;
    }
  }

  const onConnectionChange = useCallback(
    async (payload) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus("connecting");
        const { reason } = payload;
        if (reason === "failover") {
          setLoadingText("Session Disconnected,Try to reconnect");
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus("connected");
        if (isFailover) {
          setIsLoading(false);
        }
      } else if (payload.state === ConnectionState.Closed) {
        setStatus("closed");
        if (payload.reason === "ended by host") {
          alert("This meeting has been ended by host");
          await zmClient.leave(false);
          window.location.href = THANK_YOU_PAGE;
        }
      }
    },
    [isFailover]
  );

  const onMediaSDKChange = useCallback((payload) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === "success" });
  }, []);

  const onDialoutChange = useCallback(payload => {
    console.log('onDialoutChange', payload);
  }, []);

  const onAudioMerged = useCallback(payload => {
    console.log('onAudioMerged', payload);
  }, []);

  const onLeaveOrJoinSession = useCallback(async () => {
    // console.log("Je suis 1")
    if (status === "closed") {
      // console.log("Je suis 2")
      setIsLoading(true);
      setLoadingText("Starting the meeting... ");
      try {
        // console.log("Je suis 3")
        await zmClient.init("en-US", LIB_RESSOURCES, {webEndpoint: 'zoom.us'});
        // console.log("Je suis 4")
        await zmClient.join(topic, signature, name, password).then(async () => {
          // console.log("Je suis 5")
          //if(zmClient.getCurrentUserInfo().isHost || zmClient.getAllUser().length > 2) {
          if(false) {
            // console.log("Je suis 6")
            alert("Meeting not yet started by the host. Please try again later.");
            await zmClient.leave().then(() => {
              window.location.href = THANK_YOU_PAGE;
            });
            // console.log("Je suis 7")
            return;
          } else {
            // console.log("Je suis 8")
            const stream = zmClient.getMediaStream();
            // console.log("Je suis 9")
            setMediaStream(stream);
            const chatClient = zmClient.getChatClient();
            // console.log("Je suis 10")
            const commandClient = zmClient.getCommandClient();
            // console.log("Je suis 11")
            const recordingClient = zmClient.getRecordingClient();
            // console.log("Je suis 12")
            setChatClient(chatClient);
            setCommandClient(commandClient);
            setRecordingClient(recordingClient);
            setIsLoading(false);
          }
        });
      } catch (error) {
        // console.log("Je suis 13")
        console.log(error);
        await zmClient.leave();
      }
    } else if (status === "connected") {
      if(zmClient.isHost() && props.authUser) {
        await zmClient.leave(zmClient.isHost());
        window.location.href = Urls.DASHBOARD.SESSIONS;
      } else {
        await zmClient.leave(zmClient.isHost());
        window.location.href = THANK_YOU_PAGE;
      }
    }
  }, [zmClient, status, topic, signature, name, password]);

  const handleMeetingSummary = (meetingSummary: any) => {
    setMeetingSummary(meetingSummary);
  }

  const sendUserInfos = (email: string, wellbeingOne: number, wellbeingTwo: number, wellbeingThree: number) => {
    if (!email) {
      alert('Please enter valid informations');
      return;
    }
    
    let datas = {
      email: email,
      emotion: meetingSummary.emotion,
      pleasantness: meetingSummary.pleasantness,
      arousal: meetingSummary.arousal,
      attention: Number(meetingSummary?.attention) ? Math.ceil(meetingSummary?.attention*100) : 0,
      stressLevel: meetingSummary.heartRate,
      heartRate: Number(meetingSummary?.heartRate) ? Math.ceil(meetingSummary?.heartRate) : 0,
      note: meetingSummary.note ? meetingSummary.note : "No note",
      wellbeingOne: wellbeingOne,
      wellbeingTwo: wellbeingTwo, 
      wellbeingThree: wellbeingThree
    }

    setSendUserInfoLoading(true);
    SessionService.sendUserInfos(datas).then(() => {
      message.success("User informations sent successfully");
      onCancel();
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setSendUserInfoLoading(false);
    });
  }

  const onCancel = () => {
    setShowUserInfoModal(false);
    window.location.href = Urls.VIDEO.HOME;
  }

  useEffect(() => {
    zmClient.on("connection-change", onConnectionChange);
    zmClient.on("media-sdk-change", onMediaSDKChange);
    zmClient.on("dialout-state-change", onDialoutChange);
    zmClient.on("merged-audio", onAudioMerged);
    return () => {
      zmClient.off("connection-change", onConnectionChange);
      zmClient.off("media-sdk-change", onMediaSDKChange);
      zmClient.off("dialout-state-change", onDialoutChange);
      zmClient.off("merged-audio", onAudioMerged);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

  return (
    <div className="App">
      {loading && <LoadingLayer content={loadingText} />}
      {!loading && (
        <ZoomMediaContext.Provider value={{ ...mediaState, mediaStream }}>
          <ChatContext.Provider value={chatClient}>
            <RecordingContext.Provider value={recordingClient}>
              <CommandContext.Provider value={commandClient} >
                <VideoFrame onLeaveOrJoinSession={() => onLeaveOrJoinSession()} handleMeetingSummary={handleMeetingSummary} {...props} meeting={meeting} />
              </CommandContext.Provider>
            </RecordingContext.Provider>
          </ChatContext.Provider>
        </ZoomMediaContext.Provider>
      )}
      <UserInfoModal
        visible={showUserInfoModal}
        onOk={(email: string, wellbeingOne: number, wellbeingTwo: number, wellbeingThree: number) => sendUserInfos(email,wellbeingOne,wellbeingTwo,wellbeingThree)}
        onCancel={() => onCancel()}
        loading={sendUserInfoLoading}
      />
    </div>
  );
}

const mapStateToProps = ({ authUser, meetingData }: any) => {
  return { authUser: authUser.data, meetingData };
};

export default connect(mapStateToProps, { setMeetingName })(VideoCall);
