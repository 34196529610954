//export const API_BASE_URL = 'https://connect.immersively.care/api';

//export const API_BASE_URL = window.location.origin.includes('localhost') ? 'http://localhost:8080':'https://connect.immersively.care/api';
export const API_BASE_URL = window.location.origin.includes('localhost') ? 'http://localhost:8080': window.location.origin + '/api';

export const AUTH_TOKEN = 'AUTH_TOKEN';

export const OAUTH = {
	clientId: 'beinx-app',
	clientSecret: 'beinx-secret',
	grantType: 'password',
};