import axios from 'axios';
import { notification } from 'antd';
import * as Utils from '../utils/utils';
import { API_BASE_URL } from '../config/app';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 300000
})

const TOKEN_PAYLOAD_KEY = 'Authorization';

// API Request interceptor
service.interceptors.request.use(config => {

	const accessToken = localStorage.getItem('AUTH_TOKEN')
	const userReference = localStorage.getItem('USER_REFERENCE')
	
	if (accessToken && !config.url.includes("oauth/token")) {
		config.headers[TOKEN_PAYLOAD_KEY] = 'Bearer ' + accessToken
	}
		
	if (userReference && !config.url.includes("oauth/token") && !config.url.includes("kyc")) {
		config.headers["x-user-reference"] = userReference
	}

	if (config.multipart) {
		config.headers['content-type'] = 'multipart/form-data';
	 }

	if ((config.method === 'post' || config.method === 'put')) {
		// Create an object to store file data
		const fileData = {};

		// Check if fileData is present
		if (config.fileData) {
			config.fileData.forEach(f => {
				fileData[f] = config.data[f];
				delete config.data[f];
			});
		}

		config.data = Utils.objectToFormData(config.data);

		// Append files to data to send
		if (config.fileData) {
			Object.entries(fileData).forEach(item => {
				config.data.append(item[0], item[1]);
			});
		}
	}

  return config
}, error => {
	// Do something with request error here

	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {
	let notificationParam = {
		message: ''
	}
	
	if(!error.response) {
		return Promise.reject(error);
	}
	
	// Remove token and redirect 
	if (error.response.status === 403 || error.response.status === 401) {
		notificationParam.message = 'Authentication failed.';
		notificationParam.description = 'Please login again';
		localStorage.removeItem('AUTH_TOKEN');
		localStorage.removeItem('USER_REFERENCE');
		//history.push(ENTRY_ROUTE);
		//window.location.reload();
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found';
		notification.error(notificationParam)
	}

	if (error.response.status === 400) {
		notificationParam.message = 'Unexpected error or insufficient credits.';
		notification.error(notificationParam)
	}

	if (error.response.status === 409) {
		notificationParam.message = 'Already exists';
		notification.error(notificationParam)
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error';
		notification.error(notificationParam)
	}
	
	if (error.response.status === 508) {
		notificationParam.message = 'Time Out, check your internet connection';
		notification.error(notificationParam)
	}


	return Promise.reject(error);
});

export default service