import { useEffect } from 'react';
import {connect} from 'react-redux';
import Team from "./pages/Team/Team";
import Home from './pages/Home/Home';
import Pricing from './pages/Pricing';
import * as Urls from './utils/routes';
import Video from "./pages/Video/home";
import About from "./pages/About/About";
import Blogs from "./pages/Blogs/Blogs";
import SSOSignIn from "./pages/SSO/login";
import VideoCall from "./pages/Video/call";
import Contact from "./pages/Contact/Contact";
import SignIn from "./pages/Register/sign-in";
import SignUp from "./pages/Register/sign-up";
import ScrollTop from "./components/ScrollTop";
import SSOSignUp from "./pages/SSO/registration";
import Services from "./pages/Services/Services";
import Portfolio from "./pages/Portfolio/Portfolio";
import { setAuthUser } from './actions/AuthActions';
import TeamDetails from "./pages/TeamDetails/TeamDetails";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function App(props: any) {

  useEffect(() => {
    props.setAuthUser();
  }, []);

  return (
    <BrowserRouter basename={'/app'}>
      <ScrollTop/>
      <Routes>
        {/* <Route path={Urls.ROOT} element={<Home/>} />
        <Route path={Urls.HOME} element={<Home/>} />
        <Route path={Urls.ABOUT} element={<About/>} /> */}
        <Route path={Urls.VIDEO.HOME} element={<Video {...props} />} />
        <Route path={Urls.VIDEO.CALL} element={<VideoCall {...props}/>} />
        {/* <Route path={Urls.SERVICES} element={<Services/>} />
        <Route path={Urls.SERVICE_DETAILS} element={<ServicesDetails/>} />
        <Route path={Urls.PORTFOLIO} element={<Portfolio/>} />
        <Route path={Urls.PORTFOLIO_DETAILS} element={<PortfolioDetails/>} />
        <Route path={Urls.TEAM} element={<Team/>} />
        <Route path={Urls.TEAM_DETAILS} element={<TeamDetails/>} />
        <Route path={Urls.BLOG} element={<Blogs/>} />
        <Route path={Urls.BLOG_DETAILS} element={<BlogDetails/>} />
        <Route path={Urls.CONTACT} element={<Contact/>} />  */}
        <Route path={Urls.SIGN_IN} element={<SignIn/>}/>
        {/* <Route path={Urls.SIGN_UP} element={<SignUp/>}/>
        <Route path={Urls.PRINCING} element={<Pricing/>}/>
        <Route path={Urls.SSO.LOGIN} element={<SSOSignIn/>}/>
        <Route path={Urls.SSO.REGISTER} element={<SSOSignUp/>}/>  */}
        <Route path="/" element={<Navigate replace to={Urls.VIDEO.HOME} />} />
      </Routes>
    </BrowserRouter>
  );
}

// map state to props
const mapStateToProps = ({ authUser }: any) => {
  return { authUser };
};

export default connect(mapStateToProps, {setAuthUser})(App);
