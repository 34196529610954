import './bodySignals.css';
import { connect } from 'react-redux';
import { useEffect, useState, useContext, useReducer } from "react";
import ZoomContext from '../../../context/zoom-context';
import { Modal, Button, Select, Form, Checkbox, InputNumber } from "antd";
import { setMeetingTrackings, setMeetingSharings, setMeetingReceivedSignals, 
    setMeetingTriggers, setMeetingClientTrackings } from '../../../actions/MeetingActions';
import { capitalize } from '../../../utils/utils';
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const { Option } = Select;

const SIGNALS = [
    {
        data: 0,
        shared: true,
        label: 'Age',
        value: 'AGE',
        trigger: true,
        manyLayout: false,
        layoutName: "false",
        valueLabel: 'ageValue',
        trackingLabel: 'ageTracking',
    },
    {
        data: 0,
        shared: true,
        trigger: true,
        percentage: true,
        manyLayout: true,
        label: 'Engagement',
        value: 'ENGAGEMENT',
        layoutName: "layoutEngagement",
        valueLabel: 'valueEngagement',
        trackingLabel: 'arousalTracking',
    },
    {
        data: 0,
        shared: true,
        trigger: true,
        percentage: true,
        manyLayout: true,
        label: 'Pleasantness',
        value: 'PLEASANTNESS',
        layoutName: "layoutPleasantness",
        valueLabel: 'valuePleasantness',
        trackingLabel: 'pleasantnessTracking',
    },
    {
        data: 0,
        shared: true,
        trigger: true,
        percentage: true,
        manyLayout: true,
        label: 'Attention',
        value: 'ATTENTION',
        layoutName: "layoutAttention",
        valueLabel: 'valueAttention',
        trackingLabel: 'attentionTracking',
    },
    {
        data: "",
        shared: true,
        selected: [],
        trigger: false,
        label: 'Emotion',
        value: 'EMOTION',
        manyLayout: false,
        layoutName: "false",
        valueLabel: 'valueEmotion',
        trackingLabel: 'emotionTracking',
    },
    {
        data: "",
        shared: true,
        selected: [],
        trigger: false,
        label: 'Affect',
        value: 'AFFECT',
        manyLayout: false,
        layoutName: "false",
        valueLabel: 'valueAffect',
        trackingLabel: 'affectTracking',
    },
    {
        data: 0,
        shared: true,
        value: 'WISH',
        trigger: true,
        percentage: true,
        manyLayout: true,
        label: 'Interest',
        layoutName: "layoutWish",
        valueLabel: 'wishValue',
        trackingLabel: 'wishTracking',
    },
    {
        data: "",
        shared: true,
        trigger: false,
        value: 'GENDER',
        label: 'Gender',
        manyLayout: false,
        layoutName: "false",
        valueLabel: 'genderValue',
        trackingLabel: 'genderTracking',
    },
    {
        data: 0,
        shared: true,
        trigger: true,
        value: 'HEART',
        manyLayout: false,
        layoutName: "false",
        valueLabel: 'valueHeartBeat',
        trackingLabel: 'heartTracking',
        label: 'Heart rate + Wellbeing',
    }
];

const EMOTIONS = [
    'Angry', 'Disgust', 'Fear', 'Happy', 'Sad', 'Surprise', 'Neutral'
]

const AFFECTS = [
    'Afraid', 'Amused', 'Angry', 'Annoyed', 'Uncomfortable' ,'Anxious', 'Apathetic', 
    'Astonished', 'Bored', 'Worried', 'Calm', 'Conceited', 'Contemplative', 'Content', 
    'Convinced', 'Delighted', 'Depressed', 'Determined', 'Disappointed', 'Discontented', 
    'Distressed', 'Embarrassed', 'Enraged', 'Excited', 'Feel Well', 'Frustrated', 'Happy', 
    'Hopeful', 'Impressed', 'Melancholic', 'Peaceful', 'Pensive', 'Pleased', 'Relaxed', 'Sad', 
    'Satisfied', 'Sleepy', 'Disgust'
]

const BodySignals = (props: any) => {

	const [demoSettingBodySignalsPassed, setDemoSettingBodySignalsPassed] = useState(window.localStorage.getItem("demoSettingBodySignalsPassed") || false)
    // Define the steps
    const TOUR_STEPS = [
        {
            target: ".settings-body-signals-my",
            content:
                "Configure health and behavioral data which you want to track on yourself.",
        },
        {
            target: ".settings-body-signals-my-add",
            content:
                "Choose biomarkers which you want to track on yourself.",
        },
        {
            target: ".settings-body-signals-my-name",
            content:
                "The name of the biomarker being tracked on yourself.",
        },
        {
            target: ".settings-body-signals-my-value",
            content:
                "The current value of the biomarker being tracked on yourself.",
        },
        {
            target: ".settings-body-signals-my-refine",
            content:
                "Choose components of the biomarker you want to track on yourself.",
        },
        {
            target: ".settings-body-signals-my-share",
            content:
                "Share the biomarker with attendees. If you check this box, attendees will be able to see your data.",
        },
        {
            target: ".settings-body-signals-my-stop",
            content:
                "Stop tracking this biomarker and remove it from both your screens and attendees' screen if shared.",
        },
        {
            target: ".settings-body-signals-other",
            content: "Configure health and behavioral data which you want to track on other attendees.",
            disableBeacon: true,
        },
        {
            target: ".settings-body-signals-other-add",
            content:
                "Choose biomarkers which you want to track on other attendees. They will be able to revoke your request.",
        },
        {
            target: ".settings-body-signals-other-name",
            content:
                "The name of the biomarker being tracked on other attendees.",
        },
        {
            target: ".settings-body-signals-other-value",
            content:
                "The current value of the biomarker being tracked on other attendees.",
        },
        {
            target: ".settings-body-signals-other-refine",
            content:
                "Choose components of the biomarker you want to track on other attendees.",
        },
        {
            target: ".settings-body-signals-other-layout",
            content:
                "Choose how biomarkers of attendees will show on your screen: as a circle, line, or gauge.",
        },
        {
            target: ".settings-body-signals-other-trigger",
            content:
                "When tracking attendees, enable alarm triggers to show when the biomarker outside a specific values range.",
        },
        {
            target: ".settings-body-signals-other-trigger-min",
            content:
                "Define a minimal value below which an alarm trigger will be displayed when this biomarker is being tracked on the attendees.",
        },
        {
            target: ".settings-body-signals-other-trigger-max",
            content:
                "Define a maximal value above which an alarm trigger will be displayed when this biomarker is being tracked on the attendees.",
        },
        {
            target: ".settings-body-signals-other-stop",
            content:
                "Stop tracking this biomarker on attendees and remove it from your screen.",
        },
    ];
    
    // Define our state
    const INITIAL_STATE = {
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: TOUR_STEPS,
    };
    
    // Set up the reducer function
    const reducer = (state = INITIAL_STATE, action: { type: any; payload: any; }) => {
        switch (action.type) {
        case "START":
            return { ...state, run: true };
        case "RESET":
            return { ...state, stepIndex: 0 };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, ...action.payload };
        case "RESTART":
            return {
            ...state,
            stepIndex: 0,
            run: true,
            loading: false,
            key: new Date(),
            };
        default:
            return state;
        }
    };
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
        useEffect(() => {
        if (!localStorage.getItem("tour")) {
        dispatch({ type: "START", payload: null });
        }
        }, []);
    const callback = (data: { action: any; index: any; type: any; status: any; }) => {
      const { action, index, type, status } = data;
      if (
        action === ACTIONS.CLOSE ||
        (status === STATUS.SKIPPED && tourState.run) ||
        status === STATUS.FINISHED
        ) {
        dispatch({ type: "STOP", payload: null });
        window.localStorage.setItem('demoSettingBodySignalsPassed', 'true');
        setDemoSettingBodySignalsPassed(true);
      } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        dispatch({
          type: "NEXT_OR_PREV",
          payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
        });
      }
    };
    const startTour = () => {
      dispatch({ type: "RESTART", payload: null });
    };

    const zmClient = useContext(ZoomContext);

    const [form] = Form.useForm();
    const [selectedSignal, setSelectedSignal] = useState(null);
    const [trackedSignals, setTrackedSignals] = useState<any>([]);
    const [selectedClientSignal, setSelectedClientSignal] = useState(null);
    const [clientTrackedSignals, setClientTrackedSignals] = useState<any>([]);

    useEffect(() => {
        let tmp: any = [];
        if(props.trackings.heartTracking) {
            let signal: any = SIGNALS.find(s => s.value === "HEART");
            signal.data = props.bodySignals.heartToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('HEART');
        }

        if(props.trackings.emotionTracking) {
            let signal: any = SIGNALS.find(s => s.value === "EMOTION");
            signal.data = props.bodySignals.emotionToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('EMOTION');
        }

        if(props.trackings.attentionTracking) {
            let signal: any = SIGNALS.find(s => s.value === "ATTENTION");
            signal.data = props.bodySignals.attentionToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('ATTENTION');
        }

        if(props.trackings.pleasantnessTracking) {
            let signal: any = SIGNALS.find(s => s.value === "PLEASANTNESS");
            signal.data = props.bodySignals.pleasantnessToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('PLEASANTNESS');
        }

        if(props.trackings.arousalTracking) {
            let signal: any = SIGNALS.find(s => s.value === "ENGAGEMENT");
            signal.data = props.bodySignals.arousalToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('ENGAGEMENT');
        }

        if(props.trackings.wishTracking) {
            let signal: any = SIGNALS.find(s => s.value === "WISH");
            signal.data = props.bodySignals.wishToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('WISH');
        }

        if(props.trackings.ageTracking) {
            let signal: any = SIGNALS.find(s => s.value === "AGE");
            signal.data = props.bodySignals.ageToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('AGE');
        }

        if(props.trackings.genderTracking) {
            let signal: any = SIGNALS.find(s => s.value === "GENDER");
            signal.data = props.bodySignals.genderToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('GENDER');
        }

        if(props.trackings.affectTracking) {
            let signal: any = SIGNALS.find(s => s.value === "AFFECT");
            signal.data = props.bodySignals.affectToShare;
            trackedSignals[trackedSignals.indexOf(signal) >= 0 ? trackedSignals.indexOf(signal) : trackedSignals.length] = signal;
            setTrackedSignals([...trackedSignals]);
        } else {
            tmp.push('AFFECT');
        }
        setTrackedSignals([...trackedSignals.filter((ts: any) => !tmp.includes(ts.value))]);
    }, [props.trackings, props.bodySignals]);

    useEffect(() => {
        if(props.clientTrackings.heart) {
            let signal: any = SIGNALS.find(s => s.value === "HEART");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.emotion) {
            let signal: any = SIGNALS.find(s => s.value === "EMOTION");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.attention) {
            let signal: any = SIGNALS.find(s => s.value === "ATTENTION");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.pleasantness) {
            let signal: any = SIGNALS.find(s => s.value === "PLEASANTNESS");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.engagement) {
            let signal: any = SIGNALS.find(s => s.value === "ENGAGEMENT");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.wish) {
            let signal: any = SIGNALS.find(s => s.value === "WISH");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.age) {
            let signal: any = SIGNALS.find(s => s.value === "AGE");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.gender) {
            let signal: any = SIGNALS.find(s => s.value === "GENDER");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }

        if(props.clientTrackings.affect) {
            let signal: any = SIGNALS.find(s => s.value === "AFFECT");
            clientTrackedSignals[clientTrackedSignals.indexOf(signal) >= 0 ? clientTrackedSignals.indexOf(signal) : clientTrackedSignals.length] = signal;
            setClientTrackedSignals([...clientTrackedSignals]);
        }
    }, [props.clientTrackings])


    const registerSignal = () => {
        let signal: any = SIGNALS.find(s => s.value === selectedSignal);
        if(!trackedSignals.includes(signal) && trackedSignals.length < 5) {
            setTrackedSignals([...trackedSignals, SIGNALS.find(s => s.value === selectedSignal)]);
            props.trackings[signal.trackingLabel] = true;
            props.sharings[signal.value.toLowerCase()] = true;
            props.setMeetingTrackings(props.trackings);
            props.setMeetingSharings(props.sharings);
        }
    }

    const stopTracking = (signal: any) => {
        setTrackedSignals(trackedSignals.filter((ts: any) => ts.value !== signal.value));
        props.trackings[signal.trackingLabel] = false;
        props.sharings[signal.value.toLowerCase()] = false;
        props.setMeetingTrackings(props.trackings);
        props.setMeetingSharings(props.sharings);
    }

    const updateTrackedValue = (signal: any, field: string, value: any) => {
        signal[field] = value;
        trackedSignals[trackedSignals.indexOf(signal)] = signal;
        setTrackedSignals([...trackedSignals]);
        if(field === 'shared') {
            props.sharings[signal.value.toLowerCase()] = value;
            props.setMeetingSharings(props.sharings);
        }
    }

    const registerClientSignal = () => {
        let signal: any = SIGNALS.find(s => s.value === selectedClientSignal);
        if(!clientTrackedSignals.includes(signal) && clientTrackedSignals.length < 5) {
            setClientTrackedSignals([...clientTrackedSignals, SIGNALS.find(s => s.value === selectedClientSignal)]);
            props.clientTrackings[signal.value.toLowerCase()] = true;
            props.setMeetingClientTrackings(props.clientTrackings);
        }
    }

    const stopClientTracking = (signal: any) => {
        setClientTrackedSignals(clientTrackedSignals.filter((ts: any) => ts.value !== signal.value));
        props.clientTrackings[signal.value.toLowerCase()] = false;
        props.setMeetingClientTrackings(props.clientTrackings);
    }

    return (
        <Modal
            centered
            title="Setup behavioral and health data tracking"
            visible={props.visible}
            maskClosable={true}
            onOk={() => {
                props.onClose();
            }}
            onCancel={() => {
                props.onClose();
            }}
            width={1000}
            style={{zIndex: 10000}}
        >
            {/* @ts-expect-error Server Component */}
            {!demoSettingBodySignalsPassed && <JoyRide
                        {...tourState}
                        callback={callback}
                        showSkipButton={true}
                        styles={{
                            tooltipContainer: {
                                textAlign: "left",
                            },

                            buttonBack: {
                                marginRight: 10,
                            },

                            options: {
                                zIndex: 1000,
                            }
                        }}
                        locale={{
                        last: "End tour",
                        }}
                    />
            }	
            <div style={{ width: '100%' }}>
                <div className="settings-body-signals-other">
                <h3>Data shared with me</h3>

                { zmClient.isHost() ? 
                    <>
                        <Form 
                            form={form}
                            layout="vertical"
                            className="settings-body-signals-add d-flex flex-row align-items-end"
                        >
                            <Form.Item label="Request additional data (max. 5)" style={{ width: '80%' }}>
                                <Select placeholder={'Select data you want to view'} onChange={(e) => setSelectedClientSignal(e)} style={{ width: '100%' }}>
                                    {
                                        SIGNALS.map(signal => (
                                            <Option value={signal.value} disabled={clientTrackedSignals.map((c: any) => c.value).includes(signal.value)}>{signal.label}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ marginLeft: 20 }}>
                                <Button type="primary" className="w-100" onClick={() => registerClientSignal()}>
                                    Add
                                </Button>
                            </Form.Item>
                        </Form>
                        <table className="table table-bordered mt-20">
                            <thead>
                                <th>Name</th>
                                <th className="text-center">Current value</th>
                                <th className="text-center">Min</th>
                                <th className="text-center">Max</th>
                                <th className="text-center">Enable trigger</th>
                                <th className="text-center">Refined data</th>
                                <th className="text-center">Layout</th>
                                <th className="text-center">Action</th>
                            </thead>
                            <tbody>
                                { clientTrackedSignals.map((signal: any) => (
                                    <tr>
                                        <td className='settings-body-signals-other-name'>{signal.label}</td>
                                        {
                                            signal.value == 'HEART' ?
                                            <td className='settings-body-signals-other-value'>
                                                { props.otherSignals[signal.valueLabel]?.toFixed(2) } BPM
                                            </td> :
                                            <td className='settings-body-signals-other-value'>
                                                {signal.percentage ? 
                                                    Math.ceil(props.otherSignals[signal.valueLabel] ? props.otherSignals[signal.valueLabel] : 0) + '%' : 
                                                    props.otherSignals[signal.valueLabel] }
                                            </td>
                                        }
                                        <td className='settings-body-signals-other-trigger-min'>
                                            { signal.trigger &&
                                                <InputNumber 
                                                    onChange={(e) => {
                                                        props.otherSignals['min'+capitalize(signal.value)] = (e ? e : 0);
                                                        props.setMeetingReceivedSignals(props.otherSignals);
                                                    }} 
                                                    value={props.otherSignals['min'+capitalize(signal.value)] ? props.otherSignals['min'+capitalize(signal.value)] : 0} 
                                                    defaultValue={props.otherSignals['min'+capitalize(signal.value)] ? props.otherSignals['min'+capitalize(signal.value)] : 0} 
                                                />
                                            }
                                        </td>
                                        <td className='settings-body-signals-other-trigger-max'>
                                            { signal.trigger &&
                                                <InputNumber
                                                    onChange={(e) => {
                                                        props.otherSignals['max'+capitalize(signal.value)] = (e ? e : 100);
                                                        props.setMeetingReceivedSignals(props.otherSignals);
                                                    }} 
                                                    value={props.otherSignals['max'+capitalize(signal.value)] ? props.otherSignals['max'+capitalize(signal.value)] : 100} 
                                                    defaultValue={props.otherSignals['max'+capitalize(signal.value)] ? props.otherSignals['max'+capitalize(signal.value)] : 100} 
                                                />
                                            }
                                        </td>
                                        <td className='settings-body-signals-other-trigger'>
                                            { signal.trigger &&
                                                <input 
                                                    id='heart'
                                                    type={"checkbox"} 
                                                    style={{ color: 'white' }} 
                                                    checked={props.triggers[signal.value.toLowerCase()]} 
                                                    onChange={(e) => {
                                                        props.triggers[signal.value.toLowerCase()] = e.target.checked;
                                                        props.setMeetingTriggers(props.triggers);
                                                    }} 
                                                />
                                            }
                                        </td>
                                        <td className='settings-body-signals-other-refine'>
                                            { signal.value === 'EMOTION' && (
                                                <div className="w-100">
                                                    <Select 
                                                        mode="multiple" 
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            props.otherSignals.sharedClientEmotion = e.join(',');
                                                            props.setMeetingReceivedSignals(props.otherSignals);
                                                        }}
                                                        {...((props.otherSignals?.sharedClientEmotion?.split(',').length === 3) && { open: false })}
                                                    >
                                                        {
                                                            EMOTIONS.map(emotion => (
                                                                <Option value={emotion}>{emotion}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            )}
                                            { signal.value === 'AFFECT' && (
                                                <div className="w-100">
                                                    <Select 
                                                        mode="multiple" 
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            props.otherSignals.sharedClientAffect = e.join(',');
                                                            props.setMeetingReceivedSignals(props.otherSignals);
                                                        }}
                                                        {...((props.otherSignals?.sharedClientAffect?.split(',').length === 3) && { open: false })}
                                                    >
                                                        {
                                                            AFFECTS.map(affect => (
                                                                <Option value={affect}>{affect}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </div>
                                            )}
                                        </td>
                                        <td className='settings-body-signals-other-layout'>
                                            { signal.manyLayout ? 
                                                <div className="w-100">
                                                    <Select 
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            props.otherSignals[signal.layoutName] = e;
                                                            props.setMeetingReceivedSignals(props.otherSignals);
                                                        }}
                                                        defaultValue={props.otherSignals[signal.layoutName]}
                                                    >
                                                        <Option value={'CIRCLE'}>Circle layout</Option>
                                                        <Option value={'GAUGE'}>Gauge layout</Option>
                                                        <Option value={'GRAPH'}>Graph layout</Option>
                                                    </Select>
                                                </div> : 
                                                <div className="w-100">
                                                    No options
                                                </div>
                                            }
                                        </td>
                                        <td className="settings-body-signals-other-stop text-center">
                                            <Button danger onClick={() => stopClientTracking(signal)}>
                                                Stop tracking
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                :
                    <table className="table table-bordered mt-20">
                        <thead>
                            <th>Name</th>
                            <th className="text-center">Current value</th>
                            <th className="text-center">Min</th>
                            <th className="text-center">Max</th>
                            <th className="text-center">Enable trigger</th>
                            <th className="text-center">Layout</th>
                            <th className="text-center">Action</th>
                        </thead>
                        <tbody>
                            { props.otherSignals.availableHeartBeat && (
                                <tr>
                                    <td>HeartBeat</td>
                                    <td>{Math.ceil(Number(props.otherSignals.valueHeartBeat + 0))?.toFixed(2)+'BPM | '+Math.ceil(Number(props.otherSignals.valueHeartRate + 0))?.toFixed(2)+' MS'}</td>
                                    <td>
                                        <InputNumber value={props.otherSignals.minHeartBeat} onChange={(e) => {
                                            props.otherSignals.minHeartBeat = (e ? e : 0);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <InputNumber value={props.otherSignals.maxHeartBeat} onChange={(e) => {
                                            props.otherSignals.maxHeartBeat = (e ? e : 100);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <input 
                                            id='heart'
                                            type={"checkbox"} 
                                            style={{ color: 'white' }} 
                                            checked={props.triggers.heart} 
                                            onChange={(e) => {
                                                props.triggers.heart = e.target.checked;
                                                props.setMeetingTriggers(props.triggers);
                                            }} 
                                        />
                                    </td>
                                    <td>
                                        <div className="w-100">
                                            No options
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayHeartBeat ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayHeartBeat = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayHeartBeat = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}

                            { props.otherSignals.availableAttention && (
                                <tr>
                                    <td>Attention</td>
                                    <td>{(props.otherSignals.valueAttention + 0) + '%'}</td>
                                    <td>
                                        <InputNumber value={props.otherSignals.minAttention} onChange={(e) => {
                                            props.otherSignals.minAttention = (e ? e : 0);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <InputNumber value={props.otherSignals.maxAttention} onChange={(e) => {
                                            props.otherSignals.maxAttention = (e ? e : 100);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <input 
                                            id='attention'
                                            type={"checkbox"} 
                                            style={{ color: 'white' }} 
                                            checked={props.triggers.attention} 
                                            onChange={(e) => {
                                                props.triggers.attention = e.target.checked;
                                                props.setMeetingTriggers(props.triggers);
                                            }} 
                                        />
                                    </td>
                                    <td>
                                        <div className="w-100">
                                            <Select 
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    props.otherSignals.layoutAttention = e;
                                                    props.setMeetingReceivedSignals(props.otherSignals);
                                                }}
                                                defaultValue={props.otherSignals.layoutAttention}
                                            >
                                                <Option value={'CIRCLE'}>Circle layout</Option>
                                                <Option value={'GAUGE'}>Gauge layout</Option>
                                                <Option value={'GRAPH'}>Graph layout</Option>
                                            </Select>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayAttention ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayAttention = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayAttention = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}

                            { props.otherSignals.availableEngagement && (
                                <tr>
                                    <td>Engagement</td>
                                    <td>{(props.otherSignals.valueEngagement + 0) + '%'}</td>
                                    <td>
                                        <InputNumber value={props.otherSignals.minEngagement} onChange={(e) => {
                                            props.otherSignals.minEngagement = (e ? e : 0);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <InputNumber value={props.otherSignals.maxEngagement} onChange={(e) => {
                                            props.otherSignals.maxEngagement = (e ? e : 100);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <input 
                                            id='engagement'
                                            type={"checkbox"} 
                                            style={{ color: 'white' }} 
                                            checked={props.triggers.engagement} 
                                            onChange={(e) => {
                                                props.triggers.engagement = e.target.checked;
                                                props.setMeetingTriggers(props.triggers);
                                            }} 
                                        />
                                    </td>
                                    <td>
                                        <div className="w-100">
                                            <Select 
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    props.otherSignals.layoutEngagement = e;
                                                    props.setMeetingReceivedSignals(props.otherSignals);
                                                }}
                                                defaultValue={props.otherSignals.layoutEngagement}
                                            >
                                                <Option value={'CIRCLE'}>Circle layout</Option>
                                                <Option value={'GAUGE'}>Gauge layout</Option>
                                                <Option value={'GRAPH'}>Graph layout</Option>
                                            </Select>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayEngagement ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayEngagement = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayEngagement = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}

                            { props.otherSignals.availablePleasantness && (
                                <tr>
                                    <td>Pleasantness</td>
                                    <td>{(props.otherSignals.valuePleasantness + 0) + '%'}</td>
                                    <td>
                                        <InputNumber value={props.otherSignals.minPleasantness} onChange={(e) => {
                                            props.otherSignals.minPleasantness = (e ? e : 0);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <InputNumber value={props.otherSignals.maxPleasantness} onChange={(e) => {
                                            props.otherSignals.maxPleasantness = (e ? e : 100);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <input 
                                            id='pleasantness'
                                            type={"checkbox"} 
                                            style={{ color: 'white' }} 
                                            checked={props.triggers.pleasantness} 
                                            onChange={(e) => {
                                                props.triggers.pleasantness = e.target.checked;
                                                props.setMeetingTriggers(props.triggers);
                                            }} 
                                        />
                                    </td>
                                    <td>
                                        <div className="w-100">
                                            <Select 
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    props.otherSignals.layoutPleasantness = e;
                                                    props.setMeetingReceivedSignals(props.otherSignals);
                                                }}
                                                defaultValue={props.otherSignals.layoutPleasantness}
                                            >
                                                <Option value={'CIRCLE'}>Circle layout</Option>
                                                <Option value={'GAUGE'}>Gauge layout</Option>
                                                <Option value={'GRAPH'}>Graph layout</Option>
                                            </Select>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayPleasantness ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayPleasantness = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayPleasantness = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}
                            { props.otherSignals.availableEmotion && (
                                <tr>
                                    <td>Emotion</td>
                                    <td>{props.otherSignals.valueEmotion}</td>
                                    <td colSpan={4}>
                                        <div className="w-100">
                                            No options
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayEmotion ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayEmotion = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayEmotion = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}
                            { props.otherSignals.availableAffect && (
                                <tr>
                                    <td>Affect</td>
                                    <td>{props.otherSignals.valueAffect}</td>
                                    <td colSpan={4}>
                                        <div className="w-100">
                                            No options
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayAffect ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayAffect = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayAffect = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}
                            { props.otherSignals.availableAge && (
                                <tr>
                                    <td>Age</td>
                                    <td>{props.otherSignals.ageValue + 0}</td>
                                    <td>
                                        <InputNumber value={props.otherSignals.minAge} onChange={(e) => {
                                            props.otherSignals.minAge = (e ? e : 0);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <InputNumber value={props.otherSignals.maxAge} onChange={(e) => {
                                            props.otherSignals.maxAge = (e ? e : 100);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <input 
                                            id='age'
                                            type={"checkbox"} 
                                            style={{ color: 'white' }} 
                                            checked={props.triggers.age} 
                                            onChange={(e) => {
                                                props.triggers.age = e.target.checked;
                                                props.setMeetingTriggers(props.triggers);
                                            }} 
                                        />
                                    </td>
                                    <td>
                                        <div className="w-100">
                                            No options
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayAge ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayAge = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayAge = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}
                            { props.otherSignals.availableWish && (
                                <tr>
                                    <td>Interest</td>
                                    <td>{props.otherSignals.wishValue + 0}</td>
                                    <td>
                                        <InputNumber value={props.otherSignals.minWish} onChange={(e) => {
                                            props.otherSignals.minWish = (e ? e : 0);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <InputNumber value={props.otherSignals.maxWish} onChange={(e) => {
                                            props.otherSignals.maxWish = (e ? e : 100);
                                            props.setMeetingReceivedSignals(props.otherSignals);
                                        }} />
                                    </td>
                                    <td>
                                        <input 
                                            id='wish'
                                            type={"checkbox"} 
                                            style={{ color: 'white' }} 
                                            checked={props.triggers.wish} 
                                            onChange={(e) => {
                                                props.triggers.wish = e.target.checked;
                                                props.setMeetingTriggers(props.triggers);
                                            }} 
                                        />
                                    </td>
                                    <td>
                                        <div className="w-100">
                                            <Select 
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    props.otherSignals.layoutWish = e;
                                                    props.setMeetingReceivedSignals(props.otherSignals);
                                                }}
                                                defaultValue={props.otherSignals.layoutWish}
                                            >
                                                <Option value={'CIRCLE'}>Circle layout</Option>
                                                <Option value={'GAUGE'}>Gauge layout</Option>
                                                <Option value={'GRAPH'}>Graph layout</Option>
                                            </Select>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayWish ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayWish = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayWish = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}
                            { props.otherSignals.availableGender && (
                                <tr>
                                    <td>Gender</td>
                                    <td>{props.otherSignals.genderValue}</td>
                                    <td colSpan={4}>
                                        <div className="w-100">
                                            No options
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        { props.otherSignals.displayGender ?
                                            <Button danger onClick={() => {
                                                props.otherSignals.displayGender = false;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Stop display
                                            </Button> : 
                                            <Button onClick={() => {
                                                props.otherSignals.displayGender = true;
                                                props.setMeetingReceivedSignals(props.otherSignals);
                                            }}>
                                                Allow display
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
                </div>


                <div className="settings-body-signals-my">
                <h3 style={{ marginTop: 50 }}>My own data</h3>
                <Form 
                    form={form}
                    layout="vertical"
                    className="settings-body-signals-my-add d-flex flex-row align-items-end"
                >
                    <Form.Item label="Request additional data (max. 5)" style={{ width: '80%' }}>
                        <Select placeholder={'Select data you want to view'} onChange={(e) => setSelectedSignal(e)} style={{ width: '100%' }}>
                            {
                                SIGNALS.map(signal => (
                                    <Option value={signal.value} disabled={trackedSignals.includes(signal)}>{signal.label}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item style={{ marginLeft: 20 }}>
                        <Button type="primary" className="w-100" onClick={() => registerSignal()}>
                            Add
                        </Button>
                    </Form.Item>
                </Form>
                <table className="table table-bordered mt-20">
                    <thead>
                        <th>Name</th>
                        <th className="text-center">Current value</th>
                        <th className="text-center">Shared</th>
                        <th className="text-center">Refined data</th>
                        <th className="text-center">Action</th>
                    </thead>
                    <tbody>
                        { trackedSignals.map((signal: any) => (
                            <tr>
                                <td className="settings-body-signals-my-name">{signal.label}</td>
                                { signal.value == 'HEART' ? 
                                    <td className="settings-body-signals-my-value">{ signal.data?.toFixed(2) } BPM</td> :
                                    <td className="settings-body-signals-my-value">{signal.percentage ? Math.ceil((signal.data + 0) * 100) + '%' : signal.data }</td>
                                }
                                <td className="settings-body-signals-my-share text-center">
                                    <Checkbox defaultChecked={props.sharings[signal.value.toLowerCase()]} onChange={(e) => updateTrackedValue(signal, 'shared', e.target.checked)}/>
                                </td>
                                <td className="settings-body-signals-my-refine">
                                    { signal.value === 'EMOTION' && (
                                        <div className="w-100">
                                            <Select 
                                                mode="multiple" 
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    updateTrackedValue(signal, 'selected', e);
                                                    props.otherSignals.sharedEmotion = e.join(',');
                                                    props.setMeetingReceivedSignals(props.otherSignals);
                                                }}
                                                {...((signal.selected.length === 3) && { open: false })}
                                            >
                                                {
                                                    EMOTIONS.map(emotion => (
                                                        <Option value={emotion}>{emotion}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    )}
                                    { signal.value === 'AFFECT' && (
                                        <div className="w-100">
                                            <Select 
                                                mode="multiple" 
                                                style={{ width: '100%' }}
                                                onChange={(e) => {
                                                    updateTrackedValue(signal, 'selected', e);
                                                    props.otherSignals.sharedAffect = e.join(',');
                                                    props.setMeetingReceivedSignals(props.otherSignals);
                                                }}
                                                {...((signal.selected.length === 3) && { open: false })}
                                            >
                                                {
                                                    AFFECTS.map(affect => (
                                                        <Option value={affect}>{affect}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    )}
                                </td>
                                <td className="settings-body-signals-my-stop text-center">
                                    <Button danger onClick={() => stopTracking(signal)}>
                                        Stop tracking
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
        </Modal>
    )
};

const mapStateToProps = ({ meetingData }: any) => {
    return {bodySignals: meetingData.bodySignals, trackings: meetingData.trackings, 
        sharings: meetingData.sharings, triggers: meetingData.triggers, 
        otherSignals: meetingData.otherSignals, clientTrackings: meetingData.clientTrackings }
}

export default connect(mapStateToProps, { setMeetingTrackings, setMeetingSharings, 
    setMeetingReceivedSignals, setMeetingTriggers, setMeetingClientTrackings })(BodySignals);
