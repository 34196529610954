import { useState } from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Urls from '../../../utils/routes';
import { logout } from '../../../utils/utils';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

const HomeOneHeader = (props) => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="header__area p-relative header__transparent">
               <div id="header__sticky" className={stickyMenu ? "sticky header__bottom" : "header__bottom"}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                           <div className="logo">
                              <NavLink to={Urls.ROOT}>
                                 <img src="assets/img/logo/logo.png" className='img-fluid' alt="logo" />
                              </NavLink>
                           </div>
                           <div className="logo-gradient">
                              <NavLink to={Urls.ROOT}>
                                 <img src="assets/img/logo/logo_blue.png" className='img-fluid' alt="logo" />
                              </NavLink>
                           </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                              <div className="main-menu menu_wrapper_one mt-25">
                                 <nav id="mobile-menu">
                                    <ul>
                                       <li>
                                          <NavLink to={Urls.HOME}>Home</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={Urls.VIDEO.HOME}>Video call</NavLink>
                                       </li>
                                       {/* <li>
                                          <NavLink to={Urls.PRINCING}>Pricing</NavLink>
                                       </li>*/}
                                       {
                                          !props.authUser ? (
                                             <>
                                                <li>
                                                   <NavLink to={Urls.SIGN_IN}>Sign In</NavLink>
                                                </li>
                                                <li>
                                                   <NavLink to={Urls.SIGN_UP}>Sign Up</NavLink>
                                                </li>
                                             </>
                                          ) : (
                                             <>
                                                <li>
                                                   <a href={Urls.DASHBOARD.PROFILE}>Dashboard</a>
                                                </li>
                                                <li onClick={() => logout()}>
                                                   <NavLink to={Urls.HOME}>Log out</NavLink>
                                                </li>
                                             </>
                                          )
                                       }
                                    </ul>

                                 </nav>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="body-overlay-2"></div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />

      </>
   );
};

const mapStateToProps = ({ authUser}) => {
   return { authUser: authUser.data };
 };
 
 export default connect(mapStateToProps, {})(HomeOneHeader);