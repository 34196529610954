 import {
    SET_MEETING_NAME,
    SET_MEETING_SIGNALS,
    SET_MEETING_SHARING,
    SET_MEETING_TRIGGER,
    SET_MEETING_CREATING,
    SET_MEETING_TRACKINGS,
    SET_MEETING_OTHERS_SIGNALS,
    SET_MEETING_CLIENT_TRACKINGS
} from './types';

export const setMeetingName = (name) => (dispatch) => {
    dispatch({ type: SET_MEETING_NAME, payload: {name} });
};

export const setMeetingCreating = (creating) => (dispatch) => {
    dispatch({ type: SET_MEETING_CREATING, payload: {creating} });
};

export const setMeetingSignals = (signals) => (dispatch) => {
    dispatch({ type: SET_MEETING_SIGNALS, payload: signals });
};

export const setMeetingTrackings = (trackings) => (dispatch) => {
    dispatch({ type: SET_MEETING_TRACKINGS, payload: trackings });
};

export const setMeetingClientTrackings = (trackings) => (dispatch) => {
    dispatch({ type: SET_MEETING_CLIENT_TRACKINGS, payload: trackings });
};

export const setMeetingSharings = (sharings) => (dispatch) => {
    dispatch({ type: SET_MEETING_SHARING, payload: sharings });
};

export const setMeetingTriggers = (triggers) => (dispatch) => {
    dispatch({ type: SET_MEETING_TRIGGER, payload: triggers });
};

export const setMeetingReceivedSignals = (received) => (dispatch) => {
    dispatch({ type: SET_MEETING_OTHERS_SIGNALS, payload: received });
};

