const languages = [
    { label: 'Bulgarian', code: 'bg', tcode: 'bg' },
    { label: 'Cantonese', code: 'zh-HK', tcode: 'zh' },
    { label: 'Czech', code: 'cs', tcode: 'cs' },
    { label: 'Dutch', code: 'nl-NL', tcode: 'nl' },
    { label: 'English(UK)', code: 'en-GB', tcode: 'en-GB' },
    { label: 'English(US)', code: 'en-US', tcode: 'en-US' },
    { label: 'Finnish', code: 'fi', tcode: 'fi' },
    { label: 'French', code: 'fr-FR', tcode: 'fr' },
    { label: 'German', code: 'de-DE', tcode: 'de' },
    { label: 'Greek', code: 'el-GR', tcode: 'el' },
    { label: 'Hungarian', code: 'hu', tcode: 'hu' },
    { label: 'Italian', code: 'it-IT', tcode: 'it' },
    { label: 'Japanese', code: 'ja', tcode: 'ja' },
    { label: 'Spanish', code: 'es', tcode: 'es' },
    { label: 'Polish', code: 'pl', tcode: 'pl' },
    { label: 'Portuguese', code: 'pt-PT', tcode: 'pt-PT' },
    { label: 'Portuguese (Brasil)', code: 'pt-BR', tcode: 'pt-BR' },
    { label: 'Romanian', code: 'ro-RO', tcode: 'ro' },
    { label: 'Russian', code: 'ru', tcode: 'ru' },
    { label: 'Slovak', code: 'sk', tcode: 'sk' },
    { label: 'Swedish', code: 'sv-SE', tcode: 'sv' },
];

export default languages;