/* eslint-disable no-restricted-globals */
import './index.scss';
import App from './App';
import React from 'react';
import store from './store';
import ReactDOM from 'react-dom';
import ZoomVideo from '@zoom/videosdk';
import { Provider } from 'react-redux';
import 'react-image-lightbox/style.css';
import { devConfig } from './config/dev';
import 'bootstrap/dist/js/bootstrap.min.js';
import AllContext from './context/AllContext';
import 'react-modal-video/scss/modal-video.scss';
import ZoomContext from './context/zoom-context';
import { generateVideoToken, makeid } from './utils/utils';

let meetingArgs = Object.fromEntries(new URLSearchParams(location.search));
if (
  !meetingArgs.sdkKey ||
  !meetingArgs.name
) {
  meetingArgs = devConfig;
  meetingArgs.topic = (Object.fromEntries(new URLSearchParams(location.search)).q) ? Object.fromEntries(new URLSearchParams(location.search)).q : makeid();
}

if (!meetingArgs.signature && meetingArgs.sdkSecret) {
  if (!meetingArgs.topic) {
    meetingArgs.topic = makeid();    
  }
  meetingArgs.signature = generateVideoToken(
    meetingArgs.sdkKey,
    meetingArgs.sdkSecret,
    meetingArgs.topic,
    meetingArgs.password,
    '',
    ''
  );
}

const zmClient = ZoomVideo.createClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AllContext>
          <ZoomContext.Provider value={zmClient}>
            <App meetingArgs={meetingArgs} />
          </ZoomContext.Provider>
      </AllContext>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);