import { useState } from 'react';
import validator from 'validator';
import { Modal, Button, Slider } from 'antd';

const UserInfoModal = ({ visible, onOk, onCancel, loading }: any) => {

    const [email, setEmail] = useState('');

    const [wellbeingOne, setWellbeingOne] = useState<any>(0);
    const [wellbeingTwo, setWellbeingTwo] = useState<any>(0);
    const [wellbeingThree, setWellbeingThree] = useState<any>(0);

    const [emailError, setEmailError] = useState('')
    const [color, setColor] = useState('')

    const max = 10;
    const min = 0;

    const validateEmail = (e: any) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setEmailError('Valid Email')
            setColor('green');
            setEmail(email);
        } else {
            setEmailError('Enter valid Email!')
            setColor('red');
        }
    }


    return (
        <Modal
            title="Meeting resume"
            centered
            visible={visible}
            onOk={() => onOk(email, wellbeingOne, wellbeingTwo, wellbeingThree)}
            onCancel={() => onCancel()}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={() => onCancel()}>
                    Cancel
                </Button>,
                <Button key="submit" type='primary' loading={loading} onClick={() => onOk(email, wellbeingOne, wellbeingTwo, wellbeingThree)}>
                    Send
                </Button>
            ]}
        >
            <div className="contact__form" style={{ width: '100%' }}>
                <p>Enter an email address to  receive the resume of the meeting</p>
                <form>
                    <span style={{
                        fontWeight: 'bold',
                        color: color,
                    }}>{emailError}</span>
                    <input type="email" required placeholder="Your Email" onChange={e => validateEmail(e)} />
                    <div>
                        <h3>How do you feel about this session?</h3>
                        <div className="icon-wrapper">
                            <p style={{ fontWeight: 'bold' }}>I have just learned something new about myself</p>
                            <Slider
                                min={min}
                                max={max}
                                defaultValue={0}
                                onAfterChange={(e: any) => {
                                    setWellbeingOne(e);
                                }}
                                trackStyle={{ backgroundColor: '#78ed60' }}
                                handleStyle={{ borderColor: '#78ed60' }}
                            />
                        </div>
                        <div className="icon-wrapper">
                            <p style={{ fontWeight: 'bold' }}>I am in contact with my life task or life purpose right now</p>
                            <Slider
                                min={min}
                                max={max}
                                defaultValue={0}
                                onAfterChange={(e: any) => {
                                    setWellbeingTwo(e);
                                }}
                                trackStyle={{ backgroundColor: '#78ed60' }}
                                handleStyle={{ borderColor: '#78ed60' }}
                            />
                        </div>
                        <div className="icon-wrapper">
                            <p style={{ fontWeight: 'bold' }}>I am experiencing a joyful discovery right now</p>
                            <Slider
                                min={min}
                                max={max}
                                defaultValue={0}
                                onAfterChange={(e: any) => {
                                    setWellbeingThree(e);
                                }}
                                trackStyle={{ backgroundColor: '#78ed60' }}
                                handleStyle={{ borderColor: '#78ed60' }}
                            />
                        </div>
                    </div>

                </form>
            </div>
        </Modal>
    )
};

export default UserInfoModal;