import './signal.scss';
import 'antd/dist/antd.css';
import classNames from 'classnames';
import { connect } from 'react-redux';
import BodySignals from "./BodySignals";
import { useState, useContext, useReducer, useEffect } from 'react';
import ZoomContext from '../../../context/zoom-context';
import { Menu, Dropdown, Divider, Slider, Button } from 'antd';
import { UpOutlined, HeartFilled, CoffeeOutlined, SmileFilled, 
  AlertFilled, ThunderboltFilled, FrownOutlined, SmileOutlined, RadarChartOutlined, UserOutlined, ScanOutlined, MehOutlined } from '@ant-design/icons';
import { setMeetingSharings, setMeetingReceivedSignals } from '../../../actions/MeetingActions';
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const SignalButton = (props: any) => {

  const [demoBiomarkersDialogPassed, setDemoBiomarkersDialogPassed] = useState(window.localStorage.getItem("demoBiomarkersDialogPassed") || false)
  // Define the steps
  const TOUR_STEPS = [
    {
      target: ".biomarkers-dialog-my-data",
      content:
      "Instantly track and analyze my heart rate, wellbeing level, emotion, attention, and engagement. <b>Press enter to continue. Do not click.</b>",
      disableBeacon: true,
    },
    {
      target: ".biomarkers-dialog-screen-options",
      content:
      "Display my health and behavioral data on screen during the video call as well as biomarkers of attendees.",
      disableBeacon: true,
    },
    {
      target: ".biomarkers-dialog-chart-options",
      content:
      "Display health and behavioral data as a circular progressbar, a line chart, or a gauge chart.",
      disableBeacon: true,
    },
    {
      target: ".biomarkers-dialog-privacy-settings",
      content: "Provide explicit consent about which behavioral data you want attendees to see. Revoke access anytime during the call.",
      disableBeacon: true,
    },
    {
      target: ".biomarkers-dialog-advanced-options",
      content:
      "Define triggers and alarms when emotions reach a threashold. Configure 40+ biomarkers to track, including emotional affects, level of interest, age, and gender.",
      disableBeacon: true,
    },
  ];

  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case "START":
      return { ...state, run: true };
      case "RESET":
      return { ...state, stepIndex: 0 };
      case "STOP":
      return { ...state, run: false };
      case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
      case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
      default:
      return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    useEffect(() => {
      if (!localStorage.getItem("tour")) {
      dispatch({ type: "START", payload: null });
      }
    }, []);
    const callback = (data: { action: any; index: any; type: any; status: any; }) => {
      const { action, index, type, status } = data;
      if (
        action === ACTIONS.CLOSE ||
        (status === STATUS.SKIPPED && tourState.run) ||
        status === STATUS.FINISHED
        ) {
        dispatch({ type: "STOP", payload: null });
        window.localStorage.setItem('demoBiomarkersDialogPassed', 'true');
        setDemoBiomarkersDialogPassed(true);
      } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        dispatch({
          type: "NEXT_OR_PREV",
          payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
        });
      }
    };
    const startTour = () => {
      dispatch({ type: "RESTART", payload: null });
    };

  const { className } = props;

  const zmClient = useContext(ZoomContext);

  const [display, setDisplay] = useState('CIRCLE');
  const [visible, setVisible] = useState(false);

  const [wellbeings, setWellbeings] = useState<any>([]);
  const [wellbeing, setWellbeing] = useState<any>(10);
  const max = 10;
  const min = 0;

  const changeBodySignalShare = (signal: string, sharing: any) => {
    props.sharings[signal.toLowerCase()] = sharing;
    props.setMeetingSharings(props.sharings);
  }

  const mid = ((max - min) / 2).toFixed(5);
  const preColorCls = wellbeing >= mid ? '' : 'icon-wrapper-active';
  const nextColorCls = wellbeing >= mid ? 'icon-wrapper-active' : '';

  const menu = (
    <Menu theme="dark" className="signal-menu d-flex align-items-stretch">
      <div>
        <div>
        <p className='biomarkers-dialog-privacy-settings' style={{ color: 'white', marginTop: 10, paddingLeft: 20, paddingRight: 20, fontWeight:'bolder', fontSize: 22, marginBottom: '5px' }}>Privacy settings</p>
        { props.trackings.heartTracking && (
          <Menu.Item key="heart">
            <input type={"checkbox"} 
              id='heart'
              style={{ color: 'white' }} 
              checked={props.sharings.heart} 
              onChange={(e) => changeBodySignalShare('HEART', e.target.checked)} />
              <label id='heart' style={{ marginLeft: 10, color: 'white' }}>Share my Heart rate</label>
          </Menu.Item>
        )}
        { props.trackings.attentionTracking && (
          <Menu.Item key="attention">
            <input type={"checkbox"} 
              style={{ color: 'white' }} 
              id='attention'
              checked={props.sharings.attention} 
              onChange={(e) => changeBodySignalShare('ATTENTION', e.target.checked)} />
              <label id='attention' style={{ marginLeft: 10, color: 'white' }}>Share my Attention</label>
          </Menu.Item>
        )}
        { props.trackings.pleasantnessTracking && (
          <Menu.Item key="pleasantness">
            <input type={"checkbox"} 
              id='pleasantness'
              style={{ color: 'white' }} 
              checked={props.sharings.pleasantness} 
              onChange={(e) => changeBodySignalShare('PLEASANTNESS', e.target.checked)} />
              <label id='pleasantness' style={{ marginLeft: 10, color: 'white' }}>Share my Pleasantness</label>
          </Menu.Item>
        )}
        { props.trackings.emotionTracking && (
          <Menu.Item key="emotion">
            <input type={"checkbox"} 
              id='emotion'
              style={{ color: 'white' }} 
              checked={props.sharings.emotion} 
              onChange={(e) => changeBodySignalShare('EMOTION', e.target.checked)} />
              <label id='emotion' style={{ marginLeft: 10, color: 'white' }}>Share my Emotion</label>
          </Menu.Item>
        )}
        { props.trackings.arousalTracking && (
          <Menu.Item key="arousal">
            <input type={"checkbox"} 
              id='arousal'
              style={{ color: 'white' }} 
              checked={props.sharings.engagement} 
              onChange={(e) => changeBodySignalShare('ENGAGEMENT', e.target.checked)} />
              <label id='arousal' style={{ marginLeft: 10, color: 'white' }}>Share my Engagement</label>
          </Menu.Item>
        )}

        { props.trackings.ageTracking && (
          <Menu.Item key="age">
            <input type={"checkbox"} 
              id='age'
              style={{ color: 'white' }} 
              checked={props.sharings.age} 
              onChange={(e) => changeBodySignalShare('AGE', e.target.checked)} />
              <label id='age' style={{ marginLeft: 10, color: 'white' }}>Share my Age</label>
          </Menu.Item>
        )}

        { props.trackings.wishTracking && (
          <Menu.Item key="wish">
            <input type={"checkbox"} 
              id='wish'
              style={{ color: 'white' }} 
              checked={props.sharings.wish} 
              onChange={(e) => changeBodySignalShare('WISH', e.target.checked)} />
              <label id='wish' style={{ marginLeft: 10, color: 'white' }}>Share my Interest level</label>
          </Menu.Item>
        )}

        { props.trackings.genderTracking && (
          <Menu.Item key="gender">
            <input type={"checkbox"} 
              id='gender'
              style={{ color: 'white' }} 
              checked={props.sharings.gender} 
              onChange={(e) => changeBodySignalShare('GENDER', e.target.checked)} />
              <label id='gender' style={{ marginLeft: 10, color: 'white' }}>Share my Gender</label>
          </Menu.Item>
        )}
        
        { props.trackings.affectTracking && (
          <Menu.Item key="affect">
            <input type={"checkbox"} 
              id='affect'
              style={{ color: 'white' }} 
              checked={props.sharings.affect} 
              onChange={(e) => changeBodySignalShare('AFFECT', e.target.checked)} />
              <label id='affect' style={{ marginLeft: 10, color: 'white' }}>Share my Affects</label>
          </Menu.Item>
        )}
        <Menu.Divider />
        </div>
        <div>
        <p className='biomarkers-dialog-chart-options' style={{ color: 'white', marginTop: 10, paddingLeft: 20, paddingRight: 20, fontWeight:'bolder', fontSize: 22, marginBottom: '5px' }}>Chart options</p>
        <Menu.Item key="circle">
          <input type={"checkbox"} 
            id='circle'
            style={{ color: 'white' }} 
            checked={display === 'CIRCLE'} 
            onChange={() => {
              props.otherSignals.layoutAttention = 'CIRCLE';
              props.otherSignals.layoutEngagement = 'CIRCLE';
              props.otherSignals.layoutPleasantness = 'CIRCLE';
              setDisplay('CIRCLE');
              props.setMeetingReceivedSignals(props.otherSignals);
            }} 
          />
          <label id='circle' style={{ marginLeft: 10, color: 'white' }}>Circle</label>
        </Menu.Item>
        <Menu.Item key="gauge">
          <input type={"checkbox"} 
            style={{ color: 'white' }} 
            id='gauge'
            checked={display === 'GAUGE'} 
            onChange={() => {
              props.otherSignals.layoutAttention = 'GAUGE';
              props.otherSignals.layoutEngagement = 'GAUGE';
              props.otherSignals.layoutPleasantness = 'GAUGE';
              setDisplay('GAUGE');
              props.setMeetingReceivedSignals(props.otherSignals);
            }} 
          />
          <label id='gauge' style={{ marginLeft: 10, color: 'white' }}>Gauge</label>
        </Menu.Item>
        <Menu.Item key="graph">
          <input type={"checkbox"} 
            id='graph'
            style={{ color: 'white' }} 
            checked={display === 'GRAPH'} 
            onChange={() => {
              props.otherSignals.layoutAttention = 'GRAPH';
              props.otherSignals.layoutEngagement = 'GRAPH';
              props.otherSignals.layoutPleasantness = 'GRAPH';
              setDisplay('GRAPH');
              props.setMeetingReceivedSignals(props.otherSignals);
            }} 
          />
          <label id='graph' style={{ marginLeft: 10, color: 'white' }}>Graph</label>
        </Menu.Item>
        </div>
      </div>
      <Divider type="vertical" style={{ borderColor: 'white', margin: 0, backgroundColor: 'white', height: 'auto' }} />
      <div style={{ width: 350, paddingLeft: 20, paddingRight: 20 }}>
        <div>
        <p className='biomarkers-dialog-my-data' style={{ color: 'white', marginTop: 10, fontWeight:'bolder', fontSize: 22 }}>My health and behavioral data</p>

        { props.trackings.heartTracking && (
          <div className='d-flex align-items-baseline'>
            <HeartFilled style={{ color: 'orange', fontSize: 20, marginRight: 15 }} /> 
            <p style={{ color: 'white' }}>Heart rate: <span style={{ color: '#0ff1ee'}}>{Number(props.bodySignals.heartToShare) ? Math.ceil(Number(props.bodySignals.heartToShare)) : 0} BPM</span></p>
          </div>
        )}
        { props.trackings.heartTracking && (
          <div className='d-flex align-items-baseline'>
            <RadarChartOutlined style={{ color: 'orange', fontSize: 20, marginRight: 15 }} /> 
            <p style={{ color: 'white' }}>Wellbeing level: <span style={{ color: '#0ff1ee'}}>{Number(props.bodySignals.heartRToShare) ? Math.ceil(Number(props.bodySignals.heartRToShare)) : 0} MS</span></p>
          </div>
        )}
        { props.trackings.pleasantnessTracking && (
          <div className='d-flex align-items-baseline'>
            <CoffeeOutlined style={{ color: 'orange', fontSize: 20, marginRight: 15 }} /> 
            <p style={{ color: 'white' }}>Pleasantness: <span style={{ color: '#0ff1ee'}}>{Math.ceil(props.bodySignals.pleasantnessToShare*100)} %</span></p>
          </div>
        )}
        { props.trackings.emotionTracking && (
          <div className='d-flex align-items-baseline'>
            <SmileFilled style={{ color: 'orange', fontSize: 20, marginRight: 15 }} /> 
            <p style={{ color: 'white' }}>Emotions: <br /><span style={{ color: '#0ff1ee'}}>{props.bodySignals.emotionToShare}</span></p>
          </div>
        )}
        { props.trackings.affectTracking && (
          <div className='d-flex align-items-baseline'>
            <SmileFilled style={{ color: 'orange', fontSize: 20, marginRight: 15 }} /> 
            <p style={{ color: 'white' }}>Affects: <span style={{ color: '#0ff1ee'}}>{props.bodySignals.affectToShare}</span></p>
          </div>
        )}
        { props.trackings.attentionTracking && (
          <div className='d-flex align-items-baseline'>
            <AlertFilled style={{ color: 'orange', fontSize: 20, marginRight: 15 }} /> 
            <p style={{ color: 'white' }}>Attention: <span style={{ color: '#0ff1ee'}}>{Math.ceil(props.bodySignals.attentionToShare*100)} %</span></p>
          </div>
        )}
        { props.trackings.arousalTracking && (
          <div className='d-flex align-items-baseline'>
            <ThunderboltFilled style={{ color: 'orange', fontSize: 20, marginRight: 15 }} />
            <p style={{ color: 'white' }}>Engagement: <span style={{ color: '#0ff1ee'}}>{Math.ceil(props.bodySignals.arousalToShare*100)} %</span></p>
          </div>
        )}
        { props.trackings.ageTracking && (
          <div className='d-flex align-items-baseline'>
            <ScanOutlined style={{ color: 'orange', fontSize: 20, marginRight: 15 }} />
            <p style={{ color: 'white' }}>Age: <span style={{ color: '#0ff1ee'}}>{props.bodySignals.ageToShare}</span></p>
          </div>
        )}
        { props.trackings.wishTracking && (
          <div className='d-flex align-items-baseline'>
            <MehOutlined style={{ color: 'orange', fontSize: 20, marginRight: 15 }} />
            <p style={{ color: 'white' }}>Interest: <span style={{ color: '#0ff1ee'}}>{Math.ceil(props.bodySignals.wishToShare*100)} %</span></p>
          </div>
        )}
        { props.trackings.genderTracking && (
          <div className='d-flex align-items-baseline'>
            <UserOutlined style={{ color: 'orange', fontSize: 20, marginRight: 15 }} />
            <p style={{ color: 'white' }}>Gender: <span style={{ color: '#0ff1ee'}}>{props.bodySignals.genderToShare}</span></p>
          </div>
        )}
        </div>
        <div>
        <Menu.Divider />
          <p className='biomarkers-dialog-screen-options' style={{ color: 'white', marginTop: 10, paddingLeft: 20, paddingRight: 20, fontWeight:'bolder', fontSize: 22, marginBottom: '5px' }}>Screen options</p>
          <Menu.Item key="showOwnDatas">
            <input type={"checkbox"} 
              id='showOwnDatas'
              style={{ color: 'white' }} 
              checked={props.showOwnBodySignals} 
              onChange={() => props.setShowOwnBodySignals(!props.showOwnBodySignals)} />
              <label htmlFor='hide' style={{ marginLeft: 10, color: 'white' }}>Display my own data</label>
          </Menu.Item>
          <Menu.Item key="hide">
            <input type={"checkbox"} 
              id='hide'
              style={{ color: 'white' }} 
              checked={!props.hideBodySignals} 
              onChange={() => props.setHideBodySignals(props.hideBodySignals)} />
              <label htmlFor='hide' style={{ marginLeft: 10, color: 'white' }}>Display data shared with me</label>
          </Menu.Item>
          </div>
        {/*
        <Menu.Divider />
        <p style={{ color: 'white', marginTop: 10, fontWeight:'bolder', fontSize: 22 }}>My feedback</p>
        <div className="icon-wrapper">
          <FrownOutlined className={preColorCls} style={{ color: 'white' }} />
          <Slider 
            min={min}
            max={max}
            defaultValue={10} 
            onAfterChange={(e: any) => {
              setWellbeing(wellbeing);
              setWellbeings([...wellbeings, {value: e, date: new Date().getTime()}])
            }} 
            trackStyle={{ backgroundColor: '#78ed60' }} 
            handleStyle={{ borderColor: '#78ed60' }}
          />
          <SmileOutlined className={nextColorCls} style={{ color: 'white' }} />
        </div>
          */}
        <div>
        { zmClient.isHost() && (
          <div style={{ width: '100%'}}>
            <Button className='biomarkers-dialog-advanced-options' onClick={() => setVisible(true)}  style={{ width: '100%', marginTop: 20, marginBottom: 5 }}>Advanced settings</Button>
          </div>
        )}
        </div>
      </div>
    </Menu>
  );

  return (
    <div className={classNames('signal-footer', className)}>
      {/* @ts-expect-error Server Component */}
      {!demoBiomarkersDialogPassed && <JoyRide
                        {...tourState}
                        callback={callback}
                        showSkipButton={true}
                        styles={{
                            tooltipContainer: {
                                textAlign: "left",
                            },

                            buttonBack: {
                                marginRight: 10,
                            },

                            options: {
                                zIndex: 1000,
                                backgroundColor: '#fff',
                                beaconSize: 36,
                                overlayColor: 'rgba(0, 0, 0, 0.5)',
                                primaryColor: '#f04',
                                spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                            }
                        }}
                        locale={{
                        last: "End tour",
                        }}
                    />
        }	
        <Dropdown.Button
          className={'signal-dropdown-button'}
          size="large"
          style={{ paddingRight: 10 }}
          overlay={menu}
          trigger={['click']}
          type="ghost"
          icon={<UpOutlined style={{ fontSize: 18, marginRight: 10 }} />}
          placement="top"
        >
          <HeartFilled style={{ fontSize: 28, color: 'red', paddingLeft: 10 }}/>
        </Dropdown.Button>
        <BodySignals visible={visible && zmClient.isHost()} onClose={() => setVisible(false)} />
        <div style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                marginLeft: "30px"
              }}>
          <span>Biomarkers</span>
        </div>
    </div>
  );
};

const mapStateToProps = ({ meetingData }: any) => {
  return {
    bodySignals: meetingData.bodySignals, 
    trackings: meetingData.trackings, 
    sharings: meetingData.sharings,
    otherSignals: meetingData.otherSignals
  }
}

export default connect(mapStateToProps, { setMeetingSharings, setMeetingReceivedSignals })(SignalButton);
