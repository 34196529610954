import { KJUR } from 'jsrsasign';
import moment from 'moment';
import { API_BASE_URL, AUTH_TOKEN, OAUTH } from '../config/app';

export function generateVideoToken(
  sdkKey,
  sdkSecret,
  topic,
  passWord = '',
  sessionKey = '',
  userIdentity = '',
  roleType = 1,
) {
  let signature = '';
  try {
    const iat = Math.round(new Date().getTime() / 1000);
    const exp = iat + 60 * 60 * 2;

    // Header
    const oHeader = { alg: 'HS256', typ: 'JWT' };
    // Payload
    const oPayload = {
      app_key: sdkKey,
      iat,
      exp,
      tpc: topic,
      pwd: passWord,
      user_identity: userIdentity,
      session_key: sessionKey,
      role_type: roleType, // role=1, host, role=0 is attendee, only role=1 can start session when session not start
    };
    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret);
  } catch (e) {
    console.error(e);
  }
  return signature;
}

export function isShallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (
      objA[key] !== objB[key] ||
      !Object.prototype.hasOwnProperty.call(objB, key)
    ) {
      return false;
    }
  }

  return true;
}


export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode(("0x" + p1));
    }));
};

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export function getFullAuthorisationRequestConfig() {
  const headers = {
    Accept: 'application/json',
    Authorization: 'Basic ' + window.btoa(OAUTH.clientId + ":" + OAUTH.clientSecret)
  };
  return headers;
};

export function objectToFormData(obj) {
  let formData = new FormData();
  if (obj instanceof FormData) {
    formData = obj;
  } else {
    Object.keys(obj).forEach(key => {
      if (obj[key] instanceof Array) {
        for (let index = 0; index < obj[key].length; index++) {
          const value = obj[key][index];
          formData.append(key, value);
        }
      } else {
        formData.append(key, obj[key]);
      }
    });
  }
  return formData;
};

export function makeid(length = 0) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  if (length === 0) {
    length = 100;
  }
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export function formatTranscript(transcription) {
  const array = transcription.split(' ');
  if (array.length === 10) {
    let textSize = 0;
    for (let index = 0; index < 5; index++) {
      const element = array[index];
      textSize += element.length+1;
    }
    return transcription.slice(textSize);
  } else {
    return transcription;

  }
}

export function getNumberOfMinutes(date, currentDate) {
  console.log(date);
  const date1 = new Date(date);
  const date2 = new Date(currentDate);
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60;
  console.log(oneDay);
  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();
  console.log(diffInTime);
  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

export function logout() {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItemem('USER_REFERENCE');
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  window.location.replace('/');
} 

export function getFilePath(file) {
  if (file)
      if (file.startsWith('http') && file.includes(':')) {
          return file;
      } else {
          return `${API_BASE_URL}${file}`
      }
}

export function now() {
  return moment().format();
}

export function nowTimestamp() {
  return moment().format('YYYY-MM-DD HH:mm:ss');
}

export function nowTimestampUTC() {
  return moment.utc().format('YYYY-MM-DD HH:mm:ss');
}

export function formatDate(date) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getFirstBrowserLanguage () {
  var nav = window.navigator,
  browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
  i,
  language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return 'en-US';
};