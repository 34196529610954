import './video.scss';
import _ from "lodash";
import classnames from 'classnames';
import { setInterval } from 'timers';
import { Button, Modal } from "antd";
import { connect } from 'react-redux';
import Avatar from './components/avatar';
import { useShare } from './hooks/useShare';
import { VideoQuality } from '@zoom/videosdk';
import { SignalType } from '../../data/global';
import { Participant } from '../../index-types';
import { SELF_VIDEO_ID } from './video-constants';
import VideoFooter from './components/video-footer';
import 'react-circular-progressbar/dist/styles.css';
import ZoomContext from '../../context/zoom-context';
import SignalLayout from './components/signalLayout';
import MeetingService from '../../services/meetings';
// import MeetingService from '../../services/meetings';
import CommandContext from '../../context/cmd-context';
import { loadSDK } from '../../assets/js/ai-sdk-loader';
import { useMount, useSizeCallback } from '../../hooks';
import ZoomMediaContext from '../../context/media-context';
import { useReactMediaRecorder } from "react-media-recorder";
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useSpeechRecognition } from 'react-speech-recognition';
import { Time } from "@avihimsa/heart-rate-variability-analysis";
import { isShallowEqual, nowTimestampUTC } from '../../utils/utils';
import { useParticipantsChange } from './hooks/useParticipantsChange';
import React, { useContext, useRef, useState, useCallback, useEffect, useMemo, useReducer } from 'react';
import { HeartFilled, ArrowUpOutlined, ArrowDownOutlined, AlertFilled } from "@ant-design/icons";
import { isAndroidBrowser, isSupportOffscreenCanvas, isSupportWebCodecs } from '../../utils/platform';
import { setMeetingSignals, setMeetingReceivedSignals, setMeetingTrackings, setMeetingSharings, setMeetingClientTrackings } from '../../actions';
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const translate = require("deepl");
const deeplKey = process.env.REACT_APP_DEEPL_KEY;

const isUseVideoElementToDrawSelfVideo =
  isAndroidBrowser() || isSupportOffscreenCanvas();

//const significantMinutes = [10, 5];



const VideoContainer: React.FunctionComponent<any> = (props) => {
  
	const [demoVideoCallPassed, setDemoVideoCallPassed] = useState(window.localStorage.getItem("demoVideoCallPassed") || false)
    // Define the steps
  const TOUR_STEPS = [
	{
	  target: ".signal-block-heart-rate",
	  content: "Measure contact-less heart rate and wellbeing level using the computer's camera during a video call. The number of heart beats per minutes (BPM) and their variability (MS) are displayed. Low heart rate and high variability are signs of greater resilience.",
	  disableBeacon: true,
	},
	{
	  target: ".signal-block-pleasantness",
	  content:
		"Pleasantness is the affective quality referring to the intrinsic attractiveness of the video call experience, as suggested by the facial expression of attendee. Pleasantness is displayed on a scale of -100% (passive) to 100% (actively engaged) in real-time.",
	},
	{
	  target: ".signal-block-emotions",
	  content:
		"The top three emotions expressed by facial features in real-time. The likelihood of of seven core emotions is displayed – anger, disgust, fear, happiness, sadness, and surprise, neutral. High likelihood indicates a higher confidence about what the attendee is experiencing.",
	},
	{
	  target: ".signal-block-attention",
	  content:
		"Visual attention during a video call, as reflected by head pose tracking, eye gaze direction, eyes closure, and other facial features. A value close to 100% represents full attention of attendees, or close to 0% to represent distraction.",
	},
	{
	  target: ".signal-block-engagement",
	  content:
		"Engagement reflects the degree of excitement or motivational activation that a participant experiences during a video call. Engagement is displayed on a scale of -100% (for passive engagement) to 100% (for very active engagement) in real-time.",
	},
  ];
  
  // Define our state
  const INITIAL_STATE = {
	key: new Date(),
	run: false,
	continuous: true,
	loading: false,
	stepIndex: 0,
	steps: TOUR_STEPS,
  };
  
  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action: { type: any; payload: any; }) => {
	switch (action.type) {
	  case "START":
		return { ...state, run: true };
	  case "RESET":
		return { ...state, stepIndex: 0 };
	  case "STOP":
		return { ...state, run: false };
	  case "NEXT_OR_PREV":
		return { ...state, ...action.payload };
	  case "RESTART":
		return {
		  ...state,
		  stepIndex: 0,
		  run: true,
		  loading: false,
		  key: new Date(),
		};
	  default:
		return state;
	}
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    useEffect(() => {
      if (!localStorage.getItem("tour")) {
      dispatch({ type: "START", payload: null });
      }
    }, []);
    const callback = (data: { action: any; index: any; type: any; status: any; }) => {
      const { action, index, type, status } = data;
      if (
        action === ACTIONS.CLOSE ||
        (status === STATUS.SKIPPED && tourState.run) ||
        status === STATUS.FINISHED
        ) {
        dispatch({ type: "STOP", payload: null });
        window.localStorage.setItem('demoVideoCallPassed', 'true');
        setDemoVideoCallPassed(true);
      } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        dispatch({
          type: "NEXT_OR_PREV",
          payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
        });
      }
    };
    const startTour = () => {
      dispatch({ type: "RESTART", payload: null });
    };

  const zmClient = useContext(ZoomContext);

  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = useContext(ZoomMediaContext);

  const [initMorphcast, setInitMorphcast] = useState<Promise<any>>(new Promise(() => {}));

  useEffect(() => {
    let loadedInitMorphcast = loadSDK().then(CY => {
      try {
        return CY.loader()
          .licenseKey(process.env.REACT_APP_MORPHCAST_KEY)
          .addModule(CY.modules().FACE_AROUSAL_VALENCE.name, {smoothness: 0.99})
          .addModule(CY.modules().FACE_EMOTION.name, {smoothness: 0.99})
          .addModule(CY.modules().FACE_ATTENTION.name, {smoothness: 0.99})
          .addModule(CY.modules().FACE_AGE.name, {smoothness: 0.99})
          .addModule(CY.modules().FACE_WISH.name, {smoothness: 0.99})
          .addModule(CY.modules().FACE_GENDER.name, {smoothness: 0.99})
          .load();
      } catch (e) {
        console.log(e);
      }
    });
    setInitMorphcast(loadedInitMorphcast);
  }, []);

  var startMorphcast = () => initMorphcast?.then(async ({ start }: any) => {
    await start();

    window.addEventListener('CY_FACE_AROUSAL_VALENCE_RESULT', (e: any) => {
      arousalToShare.current = e.detail.output.arousal;
      pleasantnessToShare.current = e.detail.output.valence;

      let datas: any = [];
      for (var key in e.detail.output.affects38) {
        if (e.detail.output.affects38.hasOwnProperty(key)) {
            datas = [...datas, {key, value: e.detail.output.affects38[key]}];
        }
      }
      
      if(props.otherSignals.sharedAffect?.length > 0) {
        datas = datas.filter((d: any) => props.otherSignals.sharedAffect.split(',').includes(d.key));
      } else {
        datas = datas.sort((a: any, b: any) => b.value - a.value).slice(0, 3);
      }

      if(datas[0]) affectToShare.current = `${datas[0].key} ${Math.ceil(datas[0].value*100)}%`;
      if(datas[1]) affectToShare.current += `, ${datas[1].key} ${Math.ceil(datas[1].value*100)}%`; 
      if(datas[2]) affectToShare.current += `, ${datas[2].key} ${Math.ceil(datas[2].value*100)}%`;
    });

    window.addEventListener('CY_FACE_EMOTION_RESULT', (e: any) => {
      let datas: any = [];
      for (var key in e.detail.output.emotion) {
        if (e.detail.output.emotion.hasOwnProperty(key)) {
            datas = [...datas, {key, value: e.detail.output.emotion[key]}];
        }
      }

      if(props.otherSignals.sharedEmotion?.length > 0) {
        datas = datas.filter((d: any) => props.otherSignals.sharedEmotion.split(',').includes(d.key));
      } else {
        datas = datas.sort((a: any, b: any) => b.value - a.value).slice(0, 3);
      }

      if(datas[0]) emotionToShare.current = `${datas[0].key} ${Math.ceil(datas[0].value*100)}%`.replace("Disgust","Dislike");
      if(datas[1]) emotionToShare.current += `, ${datas[1].key} ${Math.ceil(datas[1].value*100)}%`.replace("Disgust","Dislike"); 
      if(datas[2]) emotionToShare.current += `, ${datas[2].key} ${Math.ceil(datas[2].value*100)}%`.replace("Disgust","Dislike");
    });

    window.addEventListener('CY_FACE_ATTENTION_RESULT', (e: any) => {
      attentionToShare.current = e.detail.output.attention;
    });

    window.addEventListener('CY_FACE_AGE_RESULT', (e: any) => {
      ageToShare.current = e.detail.output.numericAge;
    });

    window.addEventListener('CY_FACE_WISH_RESULT', (e: any) => {
      wishToShare.current = e.detail.output.wish;
    });

    window.addEventListener('CY_FACE_GENDER_RESULT', (e: any) => {
      genderToShare.current = e.detail.output.mostConfident;
    });
  });

  var stopMorphcast = () => initMorphcast?.then(({stop}: any) => {
    stop();
  });

  const microphoneRef = useRef<HTMLCanvasElement | null>(null);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareRef = useRef<HTMLCanvasElement | null>(null);
  const selfShareRef = useRef<HTMLCanvasElement & HTMLVideoElement>(null);
  const shareContainerRef = useRef<HTMLDivElement | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const previousActiveUser = useRef<Participant>();
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(
    zmClient,
    mediaStream,
    shareRef,
  );

  const currentUser = zmClient.getCurrentUserInfo();
  const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;
  const isSharing = isRecieveSharing || isStartedShare;
  const commandClient = useContext(CommandContext);
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0,
  });
  const [shareViewDimension, setShareViewDimension] = useState({
    width: 0,
    height: 0,
  });

  const [endTime, setEndTime] = useState<any>(null);
  const [startTime, setStartTime] = useState<any>(null);

  const {
    meetingArgs: { topic }
  } = props;

  let record = useRef(false);
  let recording = useRef(false);
  let recordRequestDate = useRef(0);
  const [permissionRecordVisible, setPermissionRecordVisible] = useState(false);
  const [showRecordingInProgressModal, setShowRecordingInProgressModal] = useState(false);

  let sharings = useRef<any>({});
  let trackings = useRef<any>({});
  let ageToShare = useRef(0);
  let wishToShare = useRef(0);
  let heartToShare = useRef(0);
  let genderToShare = useRef(0);
  let heartRToShare = useRef(0);
  let arousalToShare = useRef(0);
  let attentionToShare = useRef(0);
  let isListeningRef = useRef(false);
  let pleasantnessToShare = useRef(0);
  let speakerLanguage = useRef('en-US');
  let affectToShare = useRef('Undefined 0%, Undefined 0%, Undefined 0%');
  let emotionToShare = useRef('Undefined 0%, Undefined 0%, Undefined 0%');

  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition({ clearTranscriptOnListen: true });

  const {
    status,
    mediaBlobUrl,
    clearBlobUrl,
    stopRecording: stopRecord,
    startRecording: startRecord
  } = useReactMediaRecorder({ screen: true, audio: true, video: { deviceId: "0" } });

  const startRecording = () => {
    clearBlobUrl();
    return startRecord();
  };

  const stopRecording = () => {
    setEndTime(nowTimestampUTC());
    return stopRecord();
  };

  const downloadRecording = async () => {
    const time = new Date().getTime();
    const pathName = `immersively_care_connect_${topic}_${time}.mp4`;
    try {
      const mediaBlob = await fetch(mediaBlobUrl as any).then(response => response.blob());
      const file = new File([mediaBlob], pathName, { type: 'video/mp4', lastModified: time });
      setShowRecordingInProgressModal(true);
      MeetingService.saveRecordSession({file, start_time: startTime, end_time: nowTimestampUTC()}).catch(err => {
        console.log(err);
      }).finally(() => {
        setEndTime(null);
        setStartTime(null);
        setShowRecordingInProgressModal(false);
      });
      clearBlobUrl();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if(mediaBlobUrl) {
      downloadRecording();
    }
  }, [mediaBlobUrl])

  // const downloadRecording = async () => {
  //   const time = new Date().getTime();
  //   const pathName = `immersively_care_connect_${topic}_${time}.mp4`;
  //   try {
  //     if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
  //       for IE
  //       (window.navigator as any).msSaveOrOpenBlob(mediaBlobUrl, pathName);
  //     } else {
  //       for Chrome
  //       const link: any = document.createElement("a");
  //       const mediaBlob = await fetch(mediaBlobUrl as any).then(response => response.blob());
  //       const file = new File([mediaBlob], pathName, { type: 'video/mp4', lastModified: 1534584790000 });
  //       var file = new File([mediaBlob as BlobPart], pathName, {lastModified: 1534584790000});
  //       console.log(file);
  //       let url = window.URL.createObjectURL(file);
  //       MeetingService.saveRecordSession({file}).catch(err => {
  //         console.log(err);
  //       });
  //       link.href = mediaBlobUrl;
  //       link.download = pathName;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //     clearBlobUrl();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  let rr = useRef<any>([]);

  const [note, setNote] = useState('');
  const [receivedText, setReceivedText] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [translatedText, setTranslatedText] = useState('');
  const [oldTranscript, setOldTranscript] = useState<any>("");
  const [hideBodySignals, setHideBodySignals] = useState(false);
  const [showOwnBodySignals, setShowOwnBodySignals] = useState(zmClient.isHost());
  const [heartRateDirection, setHeartRateDirection] = useState(true);
  const [isRecording, setIsRecording] = useState(false);

  
  const [ageValue, setAgeValue] = useState(0);
  const [wishValue, setWishValue] = useState(0);
  const [heartValue, setHeartValue] = useState(0);
  const [genderValue, setGenderValue] = useState(0);
  const [heartRValue, setHeartRValue] = useState(0);
  const [arousalValue, setArousalValue] = useState(0);
  const [heartOldValue, setHeartOldValue] = useState(0);
  const [attentionValue, setAttentionValue] = useState(0);
  const [pleasantnessValue, setPleasantnessValue] = useState(0);
  const [affectValue, setAffectValue] = useState('Undefined 0%, Undefined 0%, Undefined 0%');
  const [emotionValue, setEmotionValue] = useState('Undefined 0%, Undefined 0%, Undefined 0%');

  const [ageSharing, setAgeSharing] = useState(false);
  const [wishSharing, setWishSharing] = useState(false);
  const [heartSharing, setHeartSharing] = useState(false);
  const [affectSharing, setAffectSharing] = useState(false);
  const [genderSharing, setGenderSharing] = useState(false);
  const [emotionSharing, setEmotionSharing] = useState(false);
  const [arousalSharing, setArousalSharing] = useState(false);
  const [attentionSharing, setAttentionSharing] = useState(false); 
  const [pleasantnessSharing, setPleasantnessSharing] = useState(false);

  useEffect(() => {
    const url = 'assets/js/heartbeat/index.js';
    const script = document.createElement('script');
    script.src = url;
    script.type = "module";
    document.body.appendChild(script);
    
    var video: any = document.querySelector("#CustomVideoElement");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(function (stream) {
        video.srcObject = stream;
        })
        .catch(function (err) {
        console.log("Something went wrong: ", err);
        });
    }

  }, []);

  useEffect(() => {
    if(zmClient.isHost() && props.authUser) {
       props.setMeetingTrackings({ageTracking: false, wishTracking: false, heartTracking: true, arousalTracking: true, 
         attentionTracking: true, pleasantnessTracking: true, emotionTracking: true, genderTracking: false, affectTracking: false });
      props.setMeetingSharings({age: false, wish: false, heart: false, engagement: false, 
        attention: false, pleasantness: false, emotion: false, gender: false, affect: false });
    }
  }, [props.authUser]);

  const translateText = async (textToTranslate: string, language: any) => {

    const targetLang = localStorage.getItem("listen-language") ? localStorage.getItem("listen-language") : 'en-US';

    if(!isListeningRef.current || targetLang === speakerLanguage.current) {
      return;
    }

    let oldText = document.getElementById("hidden-old-transcript")?.textContent;

    if(textToTranslate) {
      if(textToTranslate.length <= 1 || textToTranslate.toLowerCase() === oldText)
        return;
    } else {
      return;
    }

    translate({
      text: textToTranslate,
      free_api: true,
      target_lang: targetLang,
      auth_key: deeplKey,
    })
    .then((result: any) => {
      setTranslatedText(result.data.translations[0].text);
      storeSpeechTranscription(textToTranslate, language, targetLang, result.data.translations[0].text);
    })
    .catch((error: any) => {
        console.error(error)
    });
  }

  const activeUser = useMemo(
    () => participants.find((user) => user.userId === activeVideo),
    [participants, activeVideo],
  );

  useEffect(() => {
    if(transcript.split(' ').length > 15 || transcript.length > 150) {
      resetTranscript();
    }
  }, [transcript, resetTranscript]);

  useEffect(() => {
    isListeningRef.current = isListening;
  }, [isListening]);

  useEffect(() => {
    zmClient.on('command-channel-message', (v) => {
      let response: any = JSON.parse(v.text);

      response = response.state;
     
      try {

      if(response.rcd) {       
        let currentTimestamp = new Date().getTime();
        if((currentTimestamp - recordRequestDate.current) > 5000) {
          setPermissionRecordVisible(true);
          recordRequestDate.current = currentTimestamp;
        }
      } 
      setIsRecording(response.rcdg);

      if(response.mg) {
        setReceivedText(response.mg);
        speakerLanguage.current = response.lg;
        let targetLanguage = localStorage.getItem("listen-language") ? localStorage.getItem("listen-language") : 'en-US';
        if((isListeningRef.current && targetLanguage !== response.lg) || recording.current) {
          translateText(response.mg, response.lg);
        } else {
          setTranslatedText('');
          storeSpeechTranscription(response.mg, response.lg, targetLanguage);
        }
      }
            
      if(response.hr != null || response.hb != null) {
        let tmp = Number(document.getElementById("oldheartbeat-div")?.textContent);
        let finalData = Math.min(Math.max(props.otherSignals.minHeart, response.hb), props.otherSignals.maxHeart);
        setHeartRateDirection(tmp < finalData);
        setHeartValue(response.hb);
        setHeartRValue(response.hr);
        setHeartOldValue(finalData);
        setHeartSharing(response.shr);

        storeData('Heartbeat', response.hb);
        storeData('Wellbeing', response.hr);

        if(response.shr) {
          props.otherSignals.minHeart = props.otherSignals.minHeart ? props.otherSignals.minHeart : 0;
          props.otherSignals.maxHeart = props.otherSignals.maxHeart ? props.otherSignals.maxHeart : 100;
          props.otherSignals.valueHeartBeat = props.triggers.heart ? response.hb : finalData;
          props.otherSignals.valueHeartRate = response.hr;
          props.otherSignals.availableHeartBeat = true;
          props.otherSignals.displayHeartBeat = props.otherSignals.displayHeartBeat != null ? props.otherSignals.displayHeartBeat : true;
          props.setMeetingReceivedSignals(props.otherSignals);
        }
      } else {
        setHeartSharing(false);
      }
      if(!response.shr) {
        props.otherSignals.availableHeartBeat = false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }
        
      if(response.at != null) {
        let finalData = Math.min(Math.max(props.otherSignals.minAttention, Math.ceil(response.at*100)), props.otherSignals.maxAttention);
        setAttentionValue(Math.ceil(response.at*100));
        setAttentionSharing(response.sat);
        storeData('Attention', Math.ceil(response.at*100));
        if(response.sat) {
          props.otherSignals.minAttention = props.otherSignals.minAttention ? props.otherSignals.minAttention : 0;
          props.otherSignals.maxAttention = props.otherSignals.maxAttention ? props.otherSignals.maxAttention : 100;
          props.otherSignals.valueAttention = props.triggers.attention ? Math.ceil(response.at*100) : finalData;
          props.otherSignals.availableAttention = true;
          props.otherSignals.displayAttention = props.otherSignals.displayAttention != null ? props.otherSignals.displayAttention : true;
          props.otherSignals.layoutAttention = props.otherSignals.layoutAttention ? props.otherSignals.layoutAttention : 'CIRCLE';
          props.setMeetingReceivedSignals(props.otherSignals);
        }
      } else {
        setAttentionSharing(false);
      }

      if(!response.sat) {
        props.otherSignals.availableAttention = false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }
        
      if(response.en != null) {
        setEmotionValue(response.en);
        setEmotionSharing(response.sen);

        if(response.sen) {
          props.otherSignals.valueEmotion= response.en;
          props.otherSignals.availableEmotion= true;
          props.otherSignals.displayEmotion= props.otherSignals.displayEmotion!= null ? props.otherSignals.displayEmotion: true;
          props.setMeetingReceivedSignals(props.otherSignals);
          storeData('Emotion', response.en);
        }
      } else {
        setEmotionSharing(false);
      }

      if(!response.sen) {
        props.otherSignals.availableEmotion= false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }
              
      if(response.af != null) {
        setAffectValue(response.af);
        setAffectSharing(response.saf);

        if(response.saf) {
          props.otherSignals.valueAffect= response.af;
          props.otherSignals.availableAffect= true;
          props.otherSignals.displayAffect= props.otherSignals.displayAffect!= null ? props.otherSignals.displayAffect: true;
          props.setMeetingReceivedSignals(props.otherSignals);
          storeData('Affect', response.af);
        }
      } else {
        setAffectSharing(false);
      }

      if(!response.saf) {
        props.otherSignals.availableAffect= false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }
        
      if(response.al != null) {
        let finalData = Math.min(Math.max(props.otherSignals.minEngagement, Math.ceil(response.al*100)), props.otherSignals.maxEngagement);
        setArousalSharing(response.sal);
        setArousalValue(Math.ceil(response.al*100));

        if(response.sal) {
          props.otherSignals.minEngagement = props.otherSignals.minEngagement ? props.otherSignals.minEngagement : -100;
          props.otherSignals.maxEngagement = props.otherSignals.maxEngagement ? props.otherSignals.maxEngagement : 100;
          props.otherSignals.valueEngagement = props.triggers.engagement ? Math.ceil(response.al*100) : finalData;
          props.otherSignals.availableEngagement = true;
          props.otherSignals.displayEngagement = props.otherSignals.displayEngagement != null ? props.otherSignals.displayEngagement : true;
          props.otherSignals.layoutEngagement = props.otherSignals.layoutEngagement ? props.otherSignals.layoutEngagement : 'CIRCLE';
          props.setMeetingReceivedSignals(props.otherSignals);
          storeData('Engagement', Math.ceil(response.al*100));
        }
      } else {
        setArousalSharing(false);
      }
      if(!response.sal) {
        props.otherSignals.availableEngagement = false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }

      if(response.ps != null) {
        let finalData = Math.min(Math.max(props.otherSignals.minPleasantness, Math.ceil(response.ps*100)), props.otherSignals.maxPleasantness);
        setPleasantnessValue(Math.ceil(response.ps*100));
        setPleasantnessSharing(response.sps);

        if(response.sps) {
          props.otherSignals.minPleasantness = props.otherSignals.minPleasantness ? props.otherSignals.minPleasantness : -100;
          props.otherSignals.maxPleasantness = props.otherSignals.maxPleasantness ? props.otherSignals.maxPleasantness : 100;
          props.otherSignals.valuePleasantness = props.triggers.pleasantness ? Math.ceil(response.ps*100) : finalData;
          props.otherSignals.availablePleasantness = true;
          props.otherSignals.displayPleasantness = props.otherSignals.displayPleasantness != null ? props.otherSignals.displayPleasantness : true;
          props.otherSignals.layoutPleasantness = props.otherSignals.layoutPleasantness ? props.otherSignals.layoutPleasantness : 'CIRCLE';
          props.setMeetingReceivedSignals(props.otherSignals);
          storeData('Pleasantness', Math.ceil(response.ps*100));
        }
      } else {
        setPleasantnessSharing(false);
      }
      if(!response.sps) {
        props.otherSignals.availablePleasantness = false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }

      if(response.ag != null) {
        let finalData = Math.min(Math.max(props.otherSignals.minAge, response.ag), props.otherSignals.maxAge);
        setAgeValue(response.ag);
        setAgeSharing(response.sag);

        if(response.sag) {
          props.otherSignals.minAge = props.otherSignals.minAge ? props.otherSignals.minAge : 0;
          props.otherSignals.maxAge = props.otherSignals.maxAge ? props.otherSignals.maxAge : 100;
          props.otherSignals.ageValue = props.triggers.age ? response.ag : finalData;
          props.otherSignals.availableAge = true;
          props.otherSignals.displayAge = props.otherSignals.displayAge != null ? props.otherSignals.displayAge : true;
          props.setMeetingReceivedSignals(props.otherSignals);
          storeData('Age', response.ag);
        }
      } else {
        setAgeSharing(false);
      }
      if(!response.sag) {
        props.otherSignals.availableAge = false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }

      if(response.wi != null) {
        let finalData = Math.min(Math.max(props.otherSignals.minWish, Math.ceil(response.wi*100)), props.otherSignals.maxWish);
        setWishValue(Math.ceil(response.wi*100));
        setWishSharing(response.swi);

        if(response.swi) {
          props.otherSignals.minWish = props.otherSignals.minWish ? props.otherSignals.minWish : -100;
          props.otherSignals.maxWish = props.otherSignals.maxWish ? props.otherSignals.maxWish : 100;
          props.otherSignals.wishValue = props.triggers.wish ? Math.ceil(response.wi*100) : finalData;
          props.otherSignals.availableWish = true;
          props.otherSignals.displayWish = props.otherSignals.displayWish != null ? props.otherSignals.displayWish : true;
          props.otherSignals.layoutWish = props.otherSignals.layoutWish ? props.otherSignals.layoutWish : 'CIRCLE';
          props.setMeetingReceivedSignals(props.otherSignals);
        }
      } else {
        setWishSharing(false);
      }
      if(!response.swi) {
        props.otherSignals.availableWish = false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }

      if(response.gr != null) {
        setGenderValue(response.gr);
        setGenderSharing(response.sgr);

        if(response.sgr) {
          props.otherSignals.genderValue= response.gr;
          props.otherSignals.availableGender= true;
          props.otherSignals.displayGender= props.otherSignals.displayGender!= null ? props.otherSignals.displayGender: true;
          props.setMeetingReceivedSignals(props.otherSignals);
        }
      } else {
        setGenderSharing(false);
      }
      if(!response.sgr) {
        props.otherSignals.availableGender= false;
        props.setMeetingReceivedSignals(props.otherSignals);
      }

      if(!zmClient.isHost()) {
        props.setMeetingTrackings({ageTracking: response.cag, wishTracking: response.cwi, heartTracking: response.chb, arousalTracking: response.car, 
          attentionTracking: response.cat, pleasantnessTracking: response.cpl, emotionTracking: response.cem, 
          genderTracking: response.cge, affectTracking: response.caf });
        props.otherSignals.sharedEmotion = response.cets;
        props.otherSignals.sharedAffect = response.cats;
        props.setMeetingReceivedSignals(props.otherSignals);
      }
      
      setOldTranscript(response.mg.toLowerCase());
      
    } catch(e) {
      console.error(e);
      }
    });
    return () => {
      zmClient.off('command-channel-message', () => {
        console.log("Cancel data reception")
      });
    };
  }, [zmClient]);

  useEffect(() => {
    trackings.current = props.trackings;
  }, [props.trackings]);

  useEffect(() => {
    sharings.current = props.sharings;
  }, [props.sharings]);

  useEffect(() => {
    if(currentUser && commandClient) {
      const speechInterval = setInterval(() => {
        const text = document.getElementById("hidden-transcript")?.textContent;
        let data: any = {
          mg: text,
          lg: localStorage.getItem("listen-language") ? localStorage.getItem("listen-language") : 'en-US',
        };
        
        if(trackings.current.ageTracking) {
          data.ag = ageToShare.current;
          data.sag = sharings.current.age;
        } else {
          data.sag = false;
        }

        if(trackings.current.emotionTracking) {
          data.en = emotionToShare.current;
          data.sen = sharings.current.emotion;
        } else {
          data.sen = false;
        }

        if(trackings.current.wishTracking) {
          data.wi = wishToShare.current;
          data.swi = sharings.current.wish;
        } else {
          data.swi = false;
        }

        if(trackings.current.genderTracking) {
          data.gr = genderToShare.current;
          data.sgr = sharings.current.gender;
        } else {
          data.sgr = false;
        }

        if(trackings.current.arousalTracking) {
          data.al = arousalToShare.current;
          data.sal = sharings.current.engagement;
        } else {
          data.sal = false;
        }

        if(trackings.current.attentionTracking) {
          data.at = attentionToShare.current;
          data.sat = sharings.current.attention;
        } else {
          data.sat = false;
        }

        if(trackings.current.pleasantnessTracking) {
          data.ps = pleasantnessToShare.current;
          data.sps = sharings.current.pleasantness;
        } else {
          data.sps = false;
        }

        if(trackings.current.affectTracking) {
          data.af = affectToShare.current;
          data.saf = sharings.current.affect;
        } else {
          data.saf = false;
        }

        if(trackings.current.heartTracking) {
          data.hr = heartRToShare.current;
          data.hb = heartToShare.current;
          data.shr = sharings.current.heart;
        } else {
          data.shr = false;
        }

        if(record.current) {          
          data.rcd = record.current;
          record.current = false;
        } 
        data.rcdg = recording.current;

        if(zmClient.isHost()) {
          data.cag = props.clientTrackings.age;
          data.cwi = props.clientTrackings.wish;
          data.chb = props.clientTrackings.heart;
          data.car = props.clientTrackings.engagement;
          data.cat = props.clientTrackings.attention;
          data.cpl = props.clientTrackings.pleasantness;
          data.cem = props.clientTrackings.emotion;
          data.cge = props.clientTrackings.gender;
          data.caf = props.clientTrackings.affect;
          if(props.otherSignals.sharedClientEmotion)
            data.cets = props.otherSignals.sharedClientEmotion;
          if(props.otherSignals.sharedClientAffect)
            data.cats = props.otherSignals.sharedClientAffect;
        }

        // if(recordResponse.current) {
        //   data.rcdr = recordResponse.current;
        //   if(recordResponse.current == 'OK') {
        //     startRecording();
        //     setStartTime(nowTimestamp());
        //   }
        //   recordResponse.current = null;
        // }

        props.setMeetingSignals({affectToShare: affectToShare.current, ageToShare: ageToShare.current, wishToShare: wishToShare.current, genderToShare: genderToShare.current, 
          heartToShare: heartToShare.current, heartRToShare: heartRToShare.current, arousalToShare: arousalToShare.current, 
          attentionToShare: attentionToShare.current, pleasantnessToShare: pleasantnessToShare.current, emotionToShare: emotionToShare.current});

        commandClient?.send(JSON.stringify({
          type: 'SHARED',
          state: data
        }));

      }, 1000);
      return () => {
        clearInterval(speechInterval);
      };
    }
  }, [commandClient]);

  useParticipantsChange(zmClient, (payload) => {
    setParticipants(payload);
  });

  const onActiveVideoChange = useCallback((payload) => {
    const { userId } = payload;
    setActiveVideo(userId);
  }, []);

  useEffect(() => {
    zmClient.on('video-active-change', onActiveVideoChange);
    return () => {
      zmClient.off('video-active-change', onActiveVideoChange);
    };
  }, [zmClient, onActiveVideoChange]);

  useEffect(() => {
    if(isCurrentUserStartedVideo) {
        startMorphcast();
    } else {
        stopMorphcast();
    }
  }, [isCurrentUserStartedVideo, initMorphcast]);

  useEffect(() => {
    if (mediaStream && videoRef.current && isVideoDecodeReady) {
      if (activeUser?.bVideoOn !== previousActiveUser.current?.bVideoOn) {
        if (activeUser?.bVideoOn) {
          mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P as any,
          );
        } else {
          if (previousActiveUser.current?.bVideoOn) {
            mediaStream.stopRenderVideo(
              videoRef.current,
              previousActiveUser.current?.userId,
            );
          }
        }
      }
      if (
        activeUser?.bVideoOn &&
        previousActiveUser.current?.bVideoOn &&
        activeUser.userId !== previousActiveUser.current.userId
      ) {
        mediaStream.stopRenderVideo(
          videoRef.current,
          previousActiveUser.current?.userId,
        );
        mediaStream.renderVideo(
          videoRef.current,
          activeUser.userId,
          canvasDimension.width,
          canvasDimension.height,
          0,
          0,
          VideoQuality.Video_720P as any,
        );
      }
      previousActiveUser.current = activeUser;
    }
  }, [mediaStream, activeUser, isVideoDecodeReady, canvasDimension]);
  useMount(() => {
    if (mediaStream) {
      setActiveVideo(mediaStream.getActiveVideoId());
    }
  });


  const storeData = (nature: string, value: any) => {
    // 
    if(value && value !== 'NaN' && !value?.toString()?.includes("Undefined")) {
      let map = {
        'datetime' : nowTimestampUTC(),
        'isHost' : zmClient.isHost(),
        'value' : (value === null) ? "0" : ""+value,
        'type' : SignalType.find(st => st.known === nature)?.value,
      };
      let bodySignals = (localStorage.getItem(nature.toLowerCase()) !== null) ? JSON.parse(localStorage.getItem(nature.toLowerCase()) as any) : [];
      bodySignals.push(map);
      
    if(bodySignals.length >= 20) {
         MeetingService.saveMeetingDatas(topic, {bodySignals: JSON.stringify(bodySignals)}).then(() => {
           localStorage.removeItem(nature.toLowerCase());
         });
         localStorage.removeItem(nature.toLowerCase());
       } else {
         localStorage.setItem(nature.toLowerCase(), JSON.stringify(bodySignals));
       }
     }
  }

  const storeSpeechTranscription = (value: any, sourceLanguage: any, targetLanguage: any, translation: any = null) => {
    if(value && value !== null) {

      let transcriptions = (localStorage.getItem("transcription") !== null) ? JSON.parse(localStorage.getItem("transcription") as any) : [];

      if(transcriptions[transcriptions.length-1]?.value?.toLowerCase() == value?.toLowerCase()) {
        return;
      }

      let map: any = {
        'datetime' : nowTimestampUTC(),
        'isHost' : zmClient.isHost(),
        'value' : value,
        'sourceLanguage': sourceLanguage,
        'targetLanguage': targetLanguage
      };

      if(translation) map.translation = translation;

      transcriptions.push(map);

      console.log(transcriptions);
      
      if(transcriptions.length >= 20) {
        MeetingService.saveMeetingTranscriptionSpeech(topic, {data: JSON.stringify(transcriptions)});
        localStorage.removeItem("transcription");
      } else {
        localStorage.setItem("transcription", JSON.stringify(transcriptions));
      }
    }
  }

  useEffect(() => {
    if (isSharing && shareContainerRef.current) {
      const { width, height } = sharedContentDimension;
      const { width: containerWidth, height: containerHeight } = containerDimension;
      const ratio = Math.min(
        containerWidth / width,
        containerHeight / height,
        1
      );
      setShareViewDimension({
        width: Math.floor(width * ratio),
        height: Math.floor(height * ratio)
      })
    }
  }, [isSharing, sharedContentDimension, containerDimension]);

  const onShareContainerResize = useCallback(({ width, height }) => {
    _.throttle(() => {
      setContainerDimension({ width, height });
    }, 50).call(this);
  }, []);

  useSizeCallback(shareContainerRef.current, onShareContainerResize);

  useEffect(() => {
    if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
      mediaStream?.updateSharingCanvasDimension(
        shareViewDimension.width,
        shareViewDimension.height
      );
    }
  }, [mediaStream, sharedContentDimension, shareViewDimension]);

  const treatNote = (note:string) => {
    setNote(note);
  }

  const onLeaveCustom = () => {
    let meetingSummary =  {
      pleasantness: Math.ceil(pleasantnessToShare.current * 100) + '%',
      emotion: emotionToShare.current,
      arousal: Math.ceil(arousalToShare.current * 100) + '%',
      attention: Math.ceil(attentionToShare.current * 100) + '%',
      heartRate: Math.ceil(heartToShare.current)+' BPM',
      heartRRate: Math.ceil(heartRToShare.current)+ ' MS',
      note: (note) ? note : ""
    };
    props.handleMeetingSummary(meetingSummary);
    if (props.onLeaveOrJoinSession)
      props.onLeaveOrJoinSession();
  }


  useEffect(() => {
    const heartDiv: any = document.getElementById("hearbeat-div");
    const interval = setInterval(() => {
      if(Number(heartDiv.textContent) !== 0) {
        rr.current = [...rr.current, 6000/Number(heartDiv.textContent)];
        heartToShare.current = Number(heartDiv.textContent);
      }
      if(rr.current.length >= 200) {
        rr.current.splice(0, 100)
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      heartRToShare.current = Time.SDNN(rr.current);
    }, 1000 * 30); // 30 seconds
  
    return () => {
      clearInterval(interval);
    };
  }, [rr]);

  useEffect(() => {
    const interval = setInterval(() => {
      if(zmClient.isHost()) {
        MeetingService.getMeetingByTopic(topic, {inmeeting: true}).then((response:any) => {
          if (parseInt(response.minutesLeft) <= 5){
            alert("Meeting will end in " + parseInt(response.minutesLeft) + " min.");  
          }
        }).catch(err => {
          onLeaveCustom();
        });
      }
    },  1000 * 60);

    return () => {
      clearInterval(interval);
    }
  }, [zmClient.isHost()]);

  return (
    <>
    {/* @ts-expect-error Server Component */}
    {!demoVideoCallPassed && <React.Fragment><JoyRide
				{...tourState}
				callback={callback}
				showSkipButton={true}
				styles={{
				tooltipContainer: {
					textAlign: "left",
				},

				buttonBack: {
					marginRight: 10,
				},
				}}
				locale={{
				last: "End tour",
				}}
			/></React.Fragment>
			}	
    <div className="viewport">
      <div
        className={classnames('share-container', {
          'in-sharing': isSharing,
        })}
        ref={shareContainerRef}
      >
        <div
          className="share-container-viewport"
          style={{
            width: `${shareViewDimension.width}px`,
            height: `${shareViewDimension.height}px`,
          }}
        >
          <canvas
            className={classnames('share-canvas', { hidden: isStartedShare })}
            ref={shareRef}
          />
          {isSupportWebCodecs() ? (
            <video
              className={classnames('share-canvas', { hidden: isRecieveSharing })}
              ref={selfShareRef}
            />
          ) : (
            <canvas
              className={classnames('share-canvas', { hidden: isRecieveSharing })}
              ref={selfShareRef}
            />
          )}
        </div>
      </div>
      <div
        className={classnames('video-container', {
          'in-sharing': isSharing,
        })}
      >
        <canvas
          width="800"
          height="600"
          className="video-canvas"
          id="video-canvas"
          ref={videoRef}
        />

        <video autoPlay={true} id="CustomVideoElement" style={{ display: 'none' }}></video>

        {isUseVideoElementToDrawSelfVideo && (
          <video
            id={SELF_VIDEO_ID}
            width="800"
            height="600"
            className={classnames('self-video', {
              'single-self-video': participants.length === 1,
              'self-video-show': isCurrentUserStartedVideo,
            })}
          />
        )}

        {activeUser && (
          <Avatar
            participant={activeUser}
            isActive={false}
            className="single-view-avatar"
          />
        )}
      </div>
      
      <div className={`${isListening ? 'custom-datas-vertical' : 'custom-datas'}`}>   
        {!hideBodySignals && (
          <div className={`${isListening ? 'video-overlay-vertical' : 'video-overlay'}`}>
            {heartSharing && props.otherSignals.displayHeartBeat && (
              <div className={`signal-block-heart-rate signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div>
                  <p className='signal-block-title'>
                    Heart Rate 
                    {(props.triggers.heart && 
                       (heartValue < props.otherSignals.minHeart || heartValue > props.otherSignals.maxHeart)) 
                       && <AlertFilled style={{ color: 'red' }} /> }
                  </p>
                  <div className='d-flex'>
                    { heartRateDirection ?
                      <ArrowUpOutlined style={{ color: 'green', fontSize: 20 }} />
                      :
                      <ArrowDownOutlined style={{ color: 'red', fontSize: 20 }} />
                    }
                    <p className='text-center' style={{ paddingLeft: '20%', fontSize: 25, fontWeight: 'bold', color: 'white', marginBottom: 10 }}>{Number(props.otherSignals.valueHeartBeat) ? Math.ceil(Number(props.otherSignals.valueHeartBeat)) : 0}<sup style={{ fontSize: 10 }}> BPM</sup></p>
                  </div>
                  <p className='text-center' style={{ color: 'white', fontSize: 14, marginBottom: 7 }}>Wellbeing {Number(props.otherSignals.valueHeartRate) ? Math.ceil(Number(props.otherSignals.valueHeartRate)) : 0} ms</p>
                </div>
                <div>
                  <HeartFilled style={{ color: 'red', fontSize: 20 }} />
                </div>
              </div>
            )}
            {pleasantnessSharing && props.otherSignals.displayPleasantness && (
              <div className={`signal-block-pleasantness signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Pleasantness
                    {(props.triggers.pleasantness && 
                       (pleasantnessValue < props.otherSignals.minPleasantness || pleasantnessValue > props.otherSignals.maxPleasantness)) 
                       && <AlertFilled style={{ color: 'red' }} /> }
                  </p>
                  <SignalLayout nature='Pleasantness' value={props.otherSignals.valuePleasantness} needleColor={'#00fff9'} pathColor='#00fff9' textColor='#00fff9' trailColor='white' />
                </div>
              </div>
            )}
            {ageSharing && props.otherSignals.displayAge && (
              <div className={`signal-block-age signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Age
                    {(props.triggers.age && 
                       (ageValue < props.otherSignals.minAge || ageValue > props.otherSignals.maxAge)) 
                       && <AlertFilled style={{ color: 'red' }} /> }
                  </p>
                  <SignalLayout hidePercentage={true} nature='Age' value={props.otherSignals.ageValue} needleColor={'#4dffd8'} pathColor='#4dffd8' textColor='#4dffd8' trailColor='white' />
                </div>
              </div>
            )}
            {wishSharing && props.otherSignals.displayWish && (
              <div className={`signal-block-interest signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                  Interest
                    {(props.triggers.wish && 
                       (wishValue < props.otherSignals.minWish || wishValue > props.otherSignals.maxWish)) 
                       && <AlertFilled style={{ color: 'red' }} /> }
                  </p>
                  <SignalLayout nature='Wish' value={props.otherSignals.wishValue} needleColor={'#14fa5c'} pathColor='#14fa5c' textColor='#14fa5c' trailColor='white' />
                </div>
              </div>
            )}
            {emotionSharing && props.otherSignals.displayEmotion && (
              <div className={`signal-block-emotions signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div>
                  <p className='signal-block-title mb-1'>Emotions</p>
                  { props.otherSignals.valueEmotion.split(',')[0] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {props.otherSignals.valueEmotion.split(',')[0].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{props.otherSignals.valueEmotion.split(',')[0].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { props.otherSignals.valueEmotion.split(',')[1] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {props.otherSignals.valueEmotion.split(',')[1].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{props.otherSignals.valueEmotion.split(',')[1].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { props.otherSignals.valueEmotion.split(',')[2] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white', marginBottom: 10 }}>
                      {props.otherSignals.valueEmotion.split(',')[2].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{props.otherSignals.valueEmotion.split(',')[2].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {affectSharing && props.otherSignals.displayAffect && (
              <div className={`signal-block-affects signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div>
                  <p className='signal-block-title mb-1'>Affects</p>
                  { props.otherSignals.valueAffect.split(',')[0] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {props.otherSignals.valueAffect.split(',')[0].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{props.otherSignals.valueAffect.split(',')[0].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { props.otherSignals.valueAffect.split(',')[1] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {props.otherSignals.valueAffect.split(',')[1].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{props.otherSignals.valueAffect.split(',')[1].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { props.otherSignals.valueAffect.split(',')[2] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white', marginBottom: 10 }}>
                      {props.otherSignals.valueAffect.split(',')[2].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{props.otherSignals.valueAffect.split(',')[2].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {genderSharing && props.otherSignals.displayGender && (
              <div className={`signal-block-gender signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div>
                  <p className='signal-block-title mb-1'>Gender</p>
                  <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                    {props.otherSignals.genderValue}
                  </div>
                </div>
              </div>
            )}
            {attentionSharing && props.otherSignals.displayAttention && (
              <div className={`signal-block-attention signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Attention
                    {(props.triggers.attention && 
                       (attentionValue < props.otherSignals.minAttention || attentionValue > props.otherSignals.maxAttention)) 
                       && <AlertFilled style={{ color: 'red' }} /> }
                  </p>
                  <SignalLayout nature='Attention' value={props.otherSignals.valueAttention} needleColor={'yellow'} pathColor='yellow' textColor='yellow' trailColor='white' />
                </div>
              </div>
            )}
            {arousalSharing && props.otherSignals.displayEngagement && (
              <div className={`signal-block-engagement signal-block ${ isListening ? 'signal-block-vertical' : ''}`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                  Engagement
                    {(props.triggers.engagement && 
                       (arousalValue < props.otherSignals.minEngagement || arousalValue > props.otherSignals.maxEngagement)) 
                       && <AlertFilled style={{ color: 'red' }} /> }  
                  </p>
                  <SignalLayout nature='Engagement' value={props.otherSignals.valueEngagement} needleColor={'orange'} pathColor='orange' textColor='orange' trailColor='white' />
                </div>
              </div>
            )}
          </div>
        )}
      </div>







      <div className='custom-datas-vertical-right'>  
      <div style={{ background: 'white', padding: '10px',  margin: '5px', marginLeft: '10px', borderRadius: '15px', width: '150px' }} >
                     <a href={window.location.hostname} target={'_blank'}>
                        <img style={{ height: '50px' }} src={"assets/img/logo/" + window.location.hostname + "-logo.png"} alt="logo" />
                     </a>
                  </div>
        {showOwnBodySignals && (
          <div className='video-overlay-vertical'>
            {trackings.current.heartTracking && (
              <div className={`signal-block-heart-rate signal-block signal-block-vertical-right`}>
                <div>
                  <p className='signal-block-title'>
                    Heart Rate
                  </p>
                  <div className='d-flex'>
                    <p className='text-center' style={{ paddingLeft: '20%', fontSize: 25, fontWeight: 'bold', color: 'white', marginBottom: 10 }}>{Number(heartToShare.current) ? Math.ceil(Number(heartToShare.current)) : 0}<sup style={{ fontSize: 10 }}> BPM</sup></p>
                  </div>
                  <p className='text-center' style={{ color: 'white', fontSize: 14, marginBottom: 7 }}>Wellbeing {Number(heartRToShare.current) ? Math.ceil(Number(heartRToShare.current)) : 0} ms</p>
                </div>
                <div>
                  <HeartFilled style={{ color: 'red', fontSize: 20 }} />
                </div>
              </div>
            )}
            {trackings.current.pleasantnessTracking && (
              <div className={`signal-block-pleasantness signal-block signal-block-vertical-right`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Pleasantness
                  </p>
                  <SignalLayout nature='Pleasantness' value={Math.ceil(pleasantnessToShare.current*100)} needleColor={'#00fff9'} pathColor='#00fff9' textColor='#00fff9' trailColor='white' />
                </div>
              </div>
            )}
            {trackings.current.ageTracking && (
              <div className={`signal-block-age signal-block signal-block-vertical-right`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Age
                  </p>
                  <SignalLayout hidePercentage={true} nature='Age' value={ageToShare.current} needleColor={'#4dffd8'} pathColor='#4dffd8' textColor='#4dffd8' trailColor='white' />
                </div>
              </div>
            )}
            {trackings.current.wishTracking && (
              <div className={`signal-block-interest signal-block signal-block-vertical-right`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Interest
                  </p>
                  <SignalLayout nature='Wish' value={Math.ceil(wishToShare.current*100)} needleColor={'#14fa5c'} pathColor='#14fa5c' textColor='#14fa5c' trailColor='white' />
                </div>
              </div>
            )}
            {trackings.current.emotionTracking && (
              <div className={`signal-block-emotions signal-block signal-block-vertical-right`}>
                <div>
                  <p className='signal-block-title mb-1'>Emotions</p>
                  { emotionToShare.current.split(',')[0] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {emotionToShare.current.split(',')[0].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{emotionToShare.current.split(',')[0].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { emotionToShare.current.split(',')[1] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {emotionToShare.current.split(',')[1].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{emotionToShare.current.split(',')[1].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { emotionToShare.current.split(',')[2] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white', marginBottom: 10 }}>
                      {emotionToShare.current.split(',')[2].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{emotionToShare.current.split(',')[2].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {trackings.current.affectTracking && (
              <div className={`signal-block-affects signal-block signal-block-vertical-right`}>
                <div>
                  <p className='signal-block-title mb-1'>Affects</p>
                  { affectToShare.current.split(',')[0] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {affectToShare.current.split(',')[0].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{affectToShare.current.split(',')[0].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { affectToShare.current.split(',')[1] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                      {affectToShare.current.split(',')[1].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{affectToShare.current.split(',')[1].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                  { affectToShare.current.split(',')[2] && (
                    <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white', marginBottom: 10 }}>
                      {affectToShare.current.split(',')[2].trim().split(' ')[0]}&nbsp;<span style={{ fontSize: 10 }}>{affectToShare.current.split(',')[2].trim().split(' ')[1].trim()}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {trackings.current.genderTracking && (
              <div className={`signal-block-gender signal-block signal-block-vertical-right`}>
                <div>
                  <p className='signal-block-title mb-1'>Gender</p>
                  <div className='d-flex justify-content-between align-items-center' style={{ fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                    {genderToShare.current}
                  </div>
                </div>
              </div>
            )}
            {trackings.current.attentionTracking && (
              <div className={`signal-block-attention signal-block signal-block-vertical-right`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Attention
                  </p>
                  <SignalLayout nature='Attention' value={Math.ceil(attentionToShare.current*100)} needleColor={'yellow'} pathColor='yellow' textColor='yellow' trailColor='white' />
                </div>
              </div>
            )}
            {trackings.current.arousalTracking && (
              <div className={`signal-block-engagement signal-block signal-block-vertical-right`}>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                  <p className='signal-block-title' style={{ marginBottom: 8 }}>
                    Engagement
                  </p>
                  <SignalLayout nature='Engagement' value={Math.ceil(arousalToShare.current*100)} needleColor={'orange'} pathColor='orange' textColor='orange' trailColor='white' />
                </div>
              </div>
            )}
          </div>
        )}
      </div>









      <div className='custom-datas'>
        {receivedText && isListening && (
          <div className='transcription-overlay'>
            <div className='transcript-block'>
              {receivedText && (
                <div className='transcript-result-text' id="transcript-result-text">
                  {receivedText}
                </div>
              )}
              {translatedText.length > 0 && (
                <>
                  {receivedText && (<hr />)}
                  <div className='translated-result-text' id="translated-result-text">
                    {translatedText}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <div id="hearbeat-div" style={{ display: 'none' }}></div>
      <div id="oldheartbeat-div" style={{ display: 'none' }}>{heartOldValue}</div>
      <div id="hidden-transcript" style={{ display: 'none' }}>{transcript}</div>
      <div id="hidden-old-transcript" style={{ display: 'none' }}>{oldTranscript}</div>
      <VideoFooter 
        {...props} 
        sharing 
        treatNote={treatNote}
        shareRef={selfShareRef} 
        signature={props.signature} 
        className="video-operations" 
        onLeaveCustom={onLeaveCustom}
        status={status}
        isRecording={isRecording}
        mediaBlobUrl={mediaBlobUrl}
        stopRecording={stopRecording}
        microphoneRef={microphoneRef} 
        hideBodySignals={hideBodySignals} 
        setHideBodySignals={setHideBodySignals} 
        showOwnBodySignals={showOwnBodySignals} 
        setShowOwnBodySignals={setShowOwnBodySignals} 
        sendRecordRequest={(value) => {
          recording.current = value;
          record.current = value;
          if(value) {
            startRecording();
            setStartTime(nowTimestampUTC());
          }
        }}
        resetTranscript={() => resetTranscript()}
        isBrowserSupported={browserSupportsSpeechRecognition}
        setMicListening={(status: boolean) => setIsListening(status)}
      />
      <Modal
        centered
        title="Notification"
        maskClosable={false}
        visible={permissionRecordVisible}
        onOk={() => {
          setPermissionRecordVisible(false);
        }}
        onCancel={() => {
          setPermissionRecordVisible(false);
        }}
      >
        <div className="contact__form" style={{ width: '100%' }}>
          The session is being recorded.
        </div>
      </Modal>
      <Modal
        centered
        title="Uploading in progress"
        maskClosable={false}
        visible={showRecordingInProgressModal}
        onCancel={() => {
          setShowRecordingInProgressModal(false);
        }}
        onOk={() => {
          setShowRecordingInProgressModal(false);
        }}
      >
        <div className="contact__form" style={{ width: '100%' }}>
          uploading of the session recording is in progress - do not close this window
        </div>
      </Modal>
    </div>
  </>

  );
};

const mapStateToProps = ({ meetingData, authUser }: any) => {
  return {
    authUser: authUser.data,
    sharings: meetingData.sharings,
    triggers: meetingData.triggers,
    trackings: meetingData.trackings,
    otherSignals: meetingData.otherSignals,
    clientTrackings: meetingData.clientTrackings,
  }
}

export default connect(mapStateToProps, { setMeetingSignals, setMeetingSharings, setMeetingTrackings, 
  setMeetingReceivedSignals, setMeetingClientTrackings })(VideoContainer);
