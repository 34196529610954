import * as Routes from "./routes";
import fetch from '../FetchInterceptor';

export default class MeetingService {

    static createMeeting(data:any) {
        return fetch({
            url: Routes.CREATE_MEETING,
            method: 'post',
            data: data
        })
    };

    static getMeetingByTopic(topic:string, data: any = {}) {
        return fetch({
            url: Routes.GET_MEETING_BY_TOPIC(topic ? topic : ''),
            method: 'get',
            params: data
        })
    }

    static shareSpeechToMeeting(topic:string, data: any) {
        return fetch({
            url: Routes.SHARE_SPEECH(topic ? topic : ''),
            method: 'get',
            params: data
        })
    }

    static saveMeetingDatas(topic:string, data: any) {
        return fetch({
            url: Routes.SAVE_MEETING_DATAS(topic ? topic : ''),
            method: 'post',
            data: data
        })
    }

    static saveMeetingTranscriptionSpeech(topic:string, data: any) {
        return fetch({
            url: Routes.SAVE_MEETING_TRANSCRIPTION_SPEECH(topic ? topic : ''),
            method: 'post',
            data: data
        })
    }

    static saveRecordSession(data: any) {
        return fetch({
            url: Routes.UPLOAD_RECORD,
            method: 'post',
            data: data,
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }

    static getMeetingDatas(topic:string, data: any) {
        return fetch({
            url: Routes.GET_MEETING_DATAS(topic ? topic : ''),
            method: 'get',
            params: data
        })
    }

    static getMeetingTranscription(topic:string, data: any) {
        return fetch({
            url: Routes.GET_MEETING_TRANSCRIPTION_SPEECH(topic ? topic : ''),
            method: 'get',
            params: data
        })
    }
}