import React, { useState } from 'react'
import Recaptcha from "react-recaptcha";
import Input from 'antd/lib/input/Input';
import * as Urls from '../../utils/routes';
import { Button, Form, message } from 'antd';
import { AUTH_TOKEN } from '../../config/app';
import SessionService from '../../services/sessions';
import HomeTwoFooter from '../Home/HomeFooter/HomeFooter';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

function SignIn() {

    const rules = {
        login: [
            {
                required: true,
                message: "Please enter your username"
            }
        ],
        password: [
            {
                required: true,
                message: 'Please enter your password'
            }
        ]
    }

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(process.env.NODE_ENV==='development'?true:false);

    const recaptchaLoaded = () => {
        console.log("Recaptcha loaded !");
     }
  
     const onVerifyCallback = (response: any) => {
        if(response) {
            setVerified(true);
        } else {
            alert('Please, verify you are not a robot')
        }
     }

    const onSignIn = () => {
        if(!verified)
            return;
        form.validateFields().then(values => {
            setLoading(true);
            SessionService.loginUserAccount(values).then((response: any) => {
                localStorage.setItem(AUTH_TOKEN, response.access_token);
                window.location.pathname = 'app'+Urls.VIDEO.HOME;
            }).catch(err => {
                message.error('Login or password incorrect');
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    }

    return (
        <>
            <PageHelmet pageTitle="Session" />

            <HomeOneHeader />
            <CommonPageHeader title="Sign In" subtitle="Sign in" />
            <section className="about__area pb-200 pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 pr-0 col-lg-6">
                            <div className="about__thumb m-img">
                                <img src="assets/img/services/signup.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1">
                            <div className="about__content">
                                <div className="section__title mb-25">
                                    <span>Sign In</span>

                                    <h2>Join our community</h2>
                                </div>
                                <div className="contact__form w-100">
                                    <Form form={form} layout="vertical" name="register-form" onFinish={onSignIn}>
                                        <Form.Item label="Login" name="username" rules={rules.login} hasFeedback>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Password" name="password" rules={rules.password} hasFeedback>
                                            <Input type="password" />
                                        </Form.Item>
                                        <Recaptcha 
                                            sitekey={process.env.REACT_APP_GOOGLE_CATCHA}
                                            render="explicit"
                                            onloadCallback={() => recaptchaLoaded()}
                                            verifyCallback={(response: any) => onVerifyCallback(response)}
                                        />
                                        <Form.Item>
                                            <Button className='mt-20' type="primary" htmlType="submit" block loading={loading} disabled={!verified}>
                                                Sign in
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeTwoFooter />
        </>
    );
}

export default SignIn;