 import {
    SET_MEETING_NAME,
    SET_MEETING_SIGNALS,
    SET_MEETING_SHARING,
    SET_MEETING_TRIGGER,
    SET_MEETING_CREATING,
    SET_MEETING_TRACKINGS,
    SET_MEETING_OTHERS_SIGNALS,
    SET_MEETING_CLIENT_TRACKINGS,
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    name: 'Undefined',
    creating: false,

    otherSignals: {minAttention: 0, maxAttention: 100, minEngagement: 0, maxEngagement: 100, 
        minPleasantness: 0, maxPleasantness: 100, minAge: 0, maxAge: 100, minWish: 0, maxWish: 100},

    sharings: {age: true, wish: true, heart: true, engagement: true, 
        attention: true, pleasantness: true, emotion: true, gender: true, affect: true },

    triggers: {age: false, wish: false, heart: false, engagement: false, 
            attention: false, pleasantness: false, emotion: false, gender: false, affect: false },

    bodySignals: {age: 0, wish: 0, heartBeat: 0, heartRate: 0, arousal: 0, 
        attention: 0, pleasantness: 0, emotion: 'Undefined 0%, Undefined 0%, Undefined 0%', gender: 'Undefined',
        affect: 'Undefined 0%, Undefined 0%, Undefined 0%' },

    trackings: {ageTracking: false, wishTracking: false, heartTracking: false, arousalTracking: false, 
        attentionTracking: false, pleasantnessTracking: false, emotionTracking: false, genderTracking: false, affectTracking: false },

    clientTrackings: {age: false, wish: false, heart: true, engagement: true, 
            attention: true, pleasantness: true, emotion: true, gender: false, affect: false }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_MEETING_NAME:
            return { ...state, name: action.payload.name };
        case SET_MEETING_CREATING:
            return { ...state, creating: action.payload.creating };
        case SET_MEETING_SIGNALS:
            return { ...state, bodySignals: action.payload };
        case SET_MEETING_TRACKINGS:
            return { ...state, trackings: action.payload };
        case SET_MEETING_CLIENT_TRACKINGS:
            return { ...state, clientTrackings: action.payload };
        case SET_MEETING_SHARING:
            return { ...state, sharings: action.payload };
        case SET_MEETING_TRIGGER:
            return { ...state, triggers: action.payload };
        case SET_MEETING_OTHERS_SIGNALS:
            return { ...state, otherSignals: action.payload };
        default: return { ...state };
    }
}
