import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import GaugeChart from 'react-gauge-chart';
import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';

interface SignalLayoutProps {
    value: any;
    nature: string,
    otherSignals: any,
    pathColor?: string,
    textColor?: string,
    trailColor?: string,
    needleColor?: string,
    hidePercentage?: boolean,
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
        y: {
            ticks: {
              display: false,
              beginAtZero: true,
            },
            grid: {
              drawBorder: false,
              display: false,
            },
        },
        x: {
            ticks: {
                display: false,
                beginAtZero: true,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
      }
  };

  const pointToTrack = 60;

  const labels = Array(pointToTrack).fill(0);

  const SignalLayout = (props: SignalLayoutProps) => {
    
  const [values, setValues] = useState(Array(pointToTrack).fill(0));
  
  useEffect(() => {
    let newValues = values;
    newValues.shift();
    newValues.push(props.value);
    setValues(newValues);
  }, [props.value]);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        data: values,
        label: 'Dataset 2',
        pointHitRadius: 0,
        pointRadius: 0,
        borderColor: props.needleColor ? props.needleColor : props.trailColor,
        tension: 0.4
      },
    ],
  };

  return (
    <div style={props.otherSignals['layout'+props.nature] === 'GRAPH' ? {width: 150} : props.otherSignals['layout'+props.nature] === 'GAUGE' ? { width: 150 } : { width: 70, height: 70 }}>
        { props.otherSignals['layout'+props.nature] === 'GRAPH' ? 
        <Line options={options} data={data} width={150} height={100}/> :
        props.otherSignals['layout'+props.nature] === 'GAUGE' ?
          <GaugeChart id="gauge-chart2" 
              nrOfLevels={20} 
              colors={["#FF5F6D", "#38761D"]} 
              percent={props.value<0 ? 0 : props.value/100} 
              needleColor={props.needleColor}
              animate={true} 
          /> : 
          <CircularProgressbar value={Math.abs(props.value)} text={props.value + `${props.hidePercentage ? '' : '%' }`} 
            styles={
                buildStyles({
                    pathColor: props.value < 0 ? "red" : props.pathColor,
                    textColor: props.value < 0 ? "#f8b3b3" : props.textColor,
                    trailColor: props.trailColor
                })
            }
          />
        }
    </div>
  )
}

const mapStateToProps = ({ meetingData }: any) => {
  return {otherSignals: meetingData.otherSignals}
}

export default connect(mapStateToProps, {})(SignalLayout);
