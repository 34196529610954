import { Modal } from 'antd';
import { useEffect, useState } from 'react';

const ParticipantInfoModal = ({ visible, onOk, onCancel, fullName, onUpdateName, authUser, onclose }: any) => {

    const [name, setName] = useState(fullName);

    useEffect(() => {
        setName(fullName);
        if(authUser && visible) {
            onOk(fullName);
        }
    }, [fullName]);

    const onSubmit = (e: any) => {
        if(!name) {
            alert('Your name is required to join the meeting!');
            return;
        } else {
            onOk(name, e);
        }
    }

    return (
        <Modal
          title="Join a meeting"
          centered
          afterClose={() => onclose()}
          visible={visible}
          onOk={(e) => onSubmit(e)}
          onCancel={() => onCancel()}
          maskClosable={false}
        >
            <div className="contact__form" style={{ width: '100%' }}>
                <form >
                    <label>Please enter your name</label>
                    <input 
                        className='form-control mb-10' 
                        type="text" 
                        required 
                        value={name}
                        placeholder="Your Name" 
                        onKeyDown={e => {
                            if(e.key === "Enter") {
                                e.preventDefault();
                                onSubmit(e);
                            }
                        }} 
                        onChange={e => {
                            setName(e.target.value);
                            onUpdateName(e.target.value);
                        }
                    }/>
                </form>
            </div>
        </Modal>
    )
};

export default ParticipantInfoModal;