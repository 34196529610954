/* eslint-disable no-nested-ternary */
import "./speech.scss";
import 'antd/dist/antd.css';
import classNames from "classnames";
import languages from "../../../data/language";
import { MutableRefObject, useState } from "react";
import { Modal, Checkbox, Tooltip, Button } from 'antd';
import { TranslationOutlined } from '@ant-design/icons';
import { getFirstBrowserLanguage } from '../../../utils//utils';

interface SpeechButtonProps {
    className?: string;
    isListening: boolean;
    changeLanguage: () => void;
    changeListeningStatus: (e: boolean) => void,
    microphoneRef?: MutableRefObject<HTMLCanvasElement | null>;
}
const SpeechButton = (props: SpeechButtonProps) => {

    var ll = localStorage.getItem("listen-language");

    const [visible, setVisible] = useState(false);
    const [listenLang, setListenLang] = useState(ll ? ll : languages.map(l => l.tcode ).includes(getFirstBrowserLanguage()) ? getFirstBrowserLanguage() : 'en-US');

    const {
        className,
        isListening,
        microphoneRef,
        changeLanguage,
        changeListeningStatus
    } = props;
    
    return (
        <>
            <div className={classNames("invitation-footer", className)}>
                {isListening ? (
                    <Tooltip title={'stop live caption'}>
                        <Button
                            className={"speech-button bg-transparent"}
                            ref={microphoneRef}
                            onClick={() => setVisible(true)}
                            icon={<TranslationOutlined />}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title={'start live caption'}>
                        <Button
                            className={"speech-button bg-transparent"}
                            ref={microphoneRef}
                            onClick={() => setVisible(true)}
                            icon={<TranslationOutlined />}
                        />
                    </Tooltip>
                )}
                <div style={{
                            color: "white",
                            fontSize: "12px",
                            textAlign: "center",
                            marginLeft: "30px"
                        }}>
                <span>Live caption</span>
                </div>
            </div>
            <Modal
                title="Live caption settings"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                maskClosable={false}
            >
                <div className="contact__form" style={{ width: '100%' }}>
                    <form >
                        <Checkbox checked={isListening} style={{ width: '100%' }} onChange={(e) => {
                                changeListeningStatus(e.target.checked);
                            }
                        }>
                            Enable live caption and translation
                        </Checkbox>

                        <label style={{ marginTop: '30px' }}>Select your language</label>
                        <select value={listenLang} className="form-control" required onChange={e => {
                                localStorage.setItem("listen-language", e.target.value);
                                setListenLang(e.target.value);
                                changeLanguage();
                            }
                        } >
                            <option></option>
                            {
                                languages.map((language, index) => (
                                    <option key={index} value={language.tcode}>{ language.label }</option>
                                ))
                            }

                        </select>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default SpeechButton;