export const SignalType = [
    { 
        known: 'Attention',
        value: 'ATTENTION'
    }, { 
        known: 'Pleasantness',
        value: 'PLEASANTNESS'
    }, { 
        known: 'Engagement',
        value: 'AROUSSAL'
    }, { 
        known: 'Heartbeat',
        value: 'HEART_BEAT'
    }, { 
        known: 'Wellbeing',
        value: 'HEART_RATE'
    }, { 
        known: 'Emotion',
        value: 'EMOTION'
    }, { 
        known: 'Affect',
        value: 'AFFECT'
    }, { 
        known: 'Age',
        value: 'AGE'
    }
]