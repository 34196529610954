import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  MutableRefObject,
} from 'react';
import './video-footer.scss';
import { message } from 'antd';
import NoteButton from './note';
import ChatButton from './chat';
import LeaveButton from './leave';
import classNames from 'classnames';
import CameraButton from './camera';
import SpeechButton from './speech';
import SignalButton from './signals';
import RecordingButton from './recording2';
import MicrophoneButton from './microphone';
import InvitationButton from './invitation';
import { MediaDevice } from '../video-types';
import { ScreenShareButton } from './screen-share';
import { useUnmount, useMount } from '../../../hooks';
import ZoomContext from '../../../context/zoom-context';
import SpeechRecognition from 'react-speech-recognition';
import CommandContext from '../../../context/cmd-context';
import ZoomMediaContext from '../../../context/media-context';
import RecordingContext from '../../../context/recording-context';
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from '../video-constants';
import { isAndroidBrowser, isSupportOffscreenCanvas } from '../../../utils/platform';
import { DialoutState, MutedSource, AudioChangeAction, DialOutOption, RecordingStatus } from '@zoom/videosdk';

interface VideoFooterProps {
  sharing?: boolean;
  isRecording?: boolean;
  status?: string;
  mediaBlobUrl?: string;
  className?: string;
  hideBodySignals?: boolean;

  showOwnBodySignals?: boolean;
  setShowOwnBodySignals?: () => void;
  treatNote?: (note:string) => void;
  onLeaveCustom?: () => void;
  sendRecordRequest?: (value: boolean) => void;
  setRecordingStatus?: (value: boolean) => void;
  setMicListening?: (status: boolean) => void;
  resetTranscript: () => void;
  stopRecording?: () => void;
  setHideBodySignals?: () => void;
  onLeaveOrJoinSession?: () => void;
  onVideoStatusChange?: (status: boolean) => void;
  updateMySharedDatas?: (smh:boolean, sme:boolean, smar:boolean, smat:boolean, smp:boolean) => void;
  shareRef?: MutableRefObject<HTMLCanvasElement | null>;
  microphoneRef?: MutableRefObject<HTMLCanvasElement | null>;
  isBrowserSupported?: boolean;
  meetingArgs?: {
    signature: string;
    topic: string;
    name: string;
    password?: string;
  };
}

const isAudioEnable = typeof AudioWorklet === 'function';
const VideoFooter = React.memo((props: VideoFooterProps) => {
  const [audio, setAudio] = useState('');
  const { status, mediaBlobUrl, className, shareRef, sharing, microphoneRef, isBrowserSupported, isRecording,
    onLeaveCustom, treatNote, setMicListening, sendRecordRequest, stopRecording } = props;
  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const [isStartedVideo, setIsStartedVideo] = useState(false);
  const [isSupportPhone, setIsSupportPhone] = useState(false);
  const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [isStartedScreenShare, setIsStartedScreenShare] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isMirrored, setIsMirrored] = useState(false);
  const [activeMicrophone, setActiveMicrophone] = useState('');
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [activeCamera, setActiveCamera] = useState('');
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const { mediaStream } = useContext(ZoomMediaContext);
  const zmClient = useContext(ZoomContext);
  const myUserId = zmClient.getSessionInfo().userId;
  const recordingClient = useContext(RecordingContext);
  const [recordingStatus, setRecordingStatus] = useState<'' | RecordingStatus>(recordingClient?.getCloudRecordingStatus() || '');

  const [isListening, setIsListening] = useState(false);

  const changeLanguage = () => {
    const sourceLang  = localStorage.getItem("listen-language") ? localStorage.getItem("listen-language")+"" : "en-US";
    microphoneRef?.current?.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
      language: sourceLang
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeLanguage();
    }, 3000);
  
    return () => {
      clearInterval(interval);
    };
  });

  const onActiveSpeakerChange = useCallback((payload) => {
    if (Array.isArray(payload) && payload.length > 0) {
      const { userId } = payload[0];
      if(userId !== myUserId) {
        SpeechRecognition.stopListening();
      } else {
        changeLanguage();
      }
    }
  }, [isListening]);

  useEffect(() => {
    changeLanguage();
    zmClient.on('active-speaker', onActiveSpeakerChange);
    return () => {
      zmClient.off('active-speaker', onActiveSpeakerChange);
    };
  }, [zmClient, onActiveSpeakerChange, isListening]);

  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await mediaStream?.stopVideo();
      setIsStartedVideo(false);
      if (props.onVideoStatusChange)
        props.onVideoStatusChange(false);
    } else {
      if (
        isAndroidBrowser() ||
        (isSupportOffscreenCanvas() && !mediaStream?.isSupportMultipleVideos())
      ) {
        const videoElement = document.querySelector(
          `#${SELF_VIDEO_ID}`,
        ) as HTMLVideoElement;
        if (videoElement) {
          console.log("############# 1")
          //console.log(videoElement)
          await mediaStream?.startVideo({ videoElement });
        }
      } else {
        console.log("############### 2")
        await mediaStream?.startVideo({ hd: true, virtualBackground:{imageUrl: 'blur'} });
      }
      setIsStartedVideo(true);
      if (props.onVideoStatusChange)
        props.onVideoStatusChange(true);
    }
  }, [mediaStream, isStartedVideo]);

  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await mediaStream?.unmuteAudio();
        setIsMuted(false);
      } else {
        await mediaStream?.muteAudio();
        setIsMuted(true);
      }
    } else {
      await mediaStream?.startAudio();
      setIsStartedAudio(true);
    }
  }, [mediaStream, isStartedAudio, isMuted]);

  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split('|');
      if (type === 'microphone') {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === 'speaker') {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === 'leave audio') {
        if (audio === 'computer') {
          await mediaStream.stopAudio();
        } else if (audio === 'phone') {
          await mediaStream.hangup();
          setPhoneCallStatus(undefined);
        }
        setIsStartedAudio(false);
      }
    }
  };

  const onSwitchCamera = async (key: string) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);
        setActiveCamera(mediaStream.getActiveCamera());
      }
    }
  };
  const onMirrorVideo = async () => {
    await mediaStream?.mirrorVideo(!isMirrored);
    setIsMirrored(!isMirrored);
  };
  const onPhoneCall = async (code: string, phoneNumber: string, name: string, option: DialOutOption) => {
    await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
  }
  const onPhoneCallCancel = async (code: string, phoneNumber: string, option: { callMe: boolean }) => {
    if ([DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(phoneCallStatus as any)) {
      await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
      await new Promise((resolve) => { setTimeout(() => { resolve(true) }, 3000) });
    }
    return Promise.resolve();
  }

  const onHostAudioMuted = useCallback((payload) => {
    const { action, source, type } = payload;
    if (action === AudioChangeAction.Join) {
      setIsStartedAudio(true);
      setAudio(type);
    } else if (action === AudioChangeAction.Leave) {
      setIsStartedAudio(false);
    } else if (action === AudioChangeAction.Muted) {
      setIsMuted(true);
      if (source === MutedSource.PassiveByMuteOne) {
        message.info('Host muted you');
      }
    } else if (action === AudioChangeAction.Unmuted) {
      setIsMuted(false);
      if (source === 'passive') {
        message.info('Host unmuted you');
      }
    }
  }, []);

  const onScreenShareClick = useCallback(async () => {
    if (!isStartedScreenShare && shareRef && shareRef.current) {
      await mediaStream?.startShareScreen(shareRef.current);
      setIsStartedScreenShare(true);
    } else if (isStartedScreenShare) {
      await mediaStream?.stopShareScreen();
      setIsStartedScreenShare(false);
    }
  }, [mediaStream, isStartedScreenShare, shareRef]);
  const onPassivelyStopShare = useCallback(({ reason }) => {
    console.log('passively stop reason:', reason);
    setIsStartedScreenShare(false);
  }, []);
  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      setCameraList(mediaStream.getCameraList());
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onDialOutChange = useCallback((payload) => {
    setPhoneCallStatus(payload.code)
  }, []);

  const onRecordingChange = useCallback(() =>{
    setRecordingStatus(recordingClient?.getCloudRecordingStatus() || '');
  }, [recordingClient]);

  useEffect(() => {
    zmClient.on('current-audio-change', onHostAudioMuted);
    zmClient.on('passively-stop-share', onPassivelyStopShare);
    zmClient.on('device-change', onDeviceChange);
    zmClient.on('recording-change', onRecordingChange);
    zmClient.on('dialout-state-change', onDialOutChange);
    return () => {
      zmClient.off('current-audio-change', onHostAudioMuted);
      zmClient.off('passively-stop-share', onPassivelyStopShare);
      zmClient.off('device-change', onDeviceChange);
      zmClient.off('recording-change', onRecordingChange);
      zmClient.off('dialout-state-change', onDialOutChange);
    };
  }, [zmClient, onHostAudioMuted, onPassivelyStopShare, onDeviceChange, onDialOutChange, onRecordingChange]);

  useUnmount(() => {
    if (isStartedAudio) {
      mediaStream?.stopAudio();
    }
    if (isStartedVideo) {
      mediaStream?.stopVideo();
    }
    if (isStartedScreenShare) {
      mediaStream?.stopShareScreen();
    }
  });

  useMount(() => {
    setIsSupportPhone(!!mediaStream?.isSupportPhoneFeature());
    setPhoneCountryList(mediaStream?.getSupportCountryInfo() || []);
  });

  return (
    <div className={classNames('video-footer', className)}>

      {isAudioEnable && (
        <MicrophoneButton
          isStartedAudio={isStartedAudio}
          isMuted={isMuted}
          isSupportPhone={isSupportPhone}
          audio={audio}
          phoneCountryList={phoneCountryList}
          onPhoneCallClick={onPhoneCall}
          onPhoneCallCancel={onPhoneCallCancel}
          phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
          onMicrophoneClick={onMicrophoneClick}
          onMicrophoneMenuClick={onMicrophoneMenuClick}
          microphoneList={micList}
          speakerList={speakerList}
          activeMicrophone={activeMicrophone}
          activeSpeaker={activeSpeaker}
        />
      )}

      <CameraButton
        isStartedVideo={isStartedVideo}
        onCameraClick={onCameraClick}
        onSwitchCamera={onSwitchCamera}
        onMirrorVideo={onMirrorVideo}
        cameraList={cameraList}
        activeCamera={activeCamera}
        isMirrored={isMirrored}
        className='bg-transparent'
      />

      { zmClient.isHost() && (
        <InvitationButton meetingArgs={props.meetingArgs} />
      )}

      <ChatButton
        className='bg-transparent'
      />

      <SignalButton
        className='bg-transparent'
        hideBodySignals={props.hideBodySignals}
        setHideBodySignals={props.setHideBodySignals}
        showOwnBodySignals={props.showOwnBodySignals}
        updateMySharedDatas={props.updateMySharedDatas}
        setShowOwnBodySignals={props.setShowOwnBodySignals}
      />

      {isBrowserSupported ?
        <SpeechButton
          isListening={isListening}
          microphoneRef={microphoneRef}
          changeLanguage={changeLanguage}
          changeListeningStatus={(status) => {
            setIsListening(status);
            if(setMicListening)
              setMicListening(status)
          }}
        />
        :
        null
      }

      <NoteButton
        className='bg-transparent'
        treatNote={treatNote}
      />

      <RecordingButton 
        sendRecordRequest={sendRecordRequest } 
        setRecordingStatus={setRecordingStatus}
        stopRecording={stopRecording} 
        status={status} 
        mediaBlobUrl={mediaBlobUrl} 
        isRecording={isRecording}
      />


      {sharing && (
        <ScreenShareButton
          isStartedScreenShare={isStartedScreenShare}
          onScreenShareClick={onScreenShareClick}
        />
      )}

      <LeaveButton
        onExitClick={onLeaveCustom}
      />
    </div>
  );
});

export default VideoFooter;
