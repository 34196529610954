import Video from '../VideoCall/video-single';

interface VideoFrameProps {
    onLeaveOrJoinSession: () => void;
    handleMeetingSummary: (meetingSummary:any) => void;
    meeting?: any;
    meetingArgs: {
        signature: string;
        topic: string;
        name: string;
        password?: string;
    };
}

const VideoFrame = (props: VideoFrameProps) => { 
    
    return (
        <div>
            <Video 
            meeting={props.meeting} 
            meetingArgs={props.meetingArgs}
            handleMeetingSummary={props.handleMeetingSummary} 
            onLeaveOrJoinSession={props.onLeaveOrJoinSession} 
            />
        </div>
    )
};

export default VideoFrame;