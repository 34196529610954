import * as Routes from "./routes";
import fetch from '../FetchInterceptor';
import { OAUTH } from "../../config/app";
import { getFullAuthorisationRequestConfig } from "../../utils/utils";

export default class SessionService {

    static generateSessionSignature() {
        return fetch({
            url: Routes.GENERATE_SESSION_SIGNATURE,
            method: 'get'
        })
    };

    static inviteUsers(data:any) {
        return fetch({
            url: Routes.INVITE_USER,
            method: 'post',
            data: data
        })
    }

    static inviteClient(data:any) {
        return fetch({
            url: Routes.INVITE_ClIENT,
            method: 'post',
            data: data
        })
    }

    static sendCredentials(data:any) {
        return fetch({
            url: Routes.SEND_CREDENTIALS,
            method: 'post',
            data: data
        })
    }

    static sendUserInfos(data : any) {
        return fetch({
            url: Routes.SEND_USER_INFORMATIONS,
            method: 'post',
            data: data
        })
    }


    static createUserAccount(data: any) {
        let headers = getFullAuthorisationRequestConfig();
        return fetch({
            url: Routes.CREATE_USER_ACCOUNT,
            method: 'post',
            headers,
            data
        });
    }

    static loginUserAccount(data: any) {
        let headers = getFullAuthorisationRequestConfig();
        data.client_id = OAUTH.clientId;
        data.grant_type = OAUTH.grantType;
        return fetch({
            url: Routes.LOGIN_USER_ACCOUNT,
            method: 'post',
            headers,
            data
        });
    }

}