import {connect} from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import * as Urls from '../../utils/routes';
import { useState, useEffect } from 'react';
import MeetingService from "../../services/meetings";
import HomeFooter from '../Home/HomeFooter/HomeFooter';
import ParticipantInfoModal from './ParticipantInfoModal';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import { formatDate, nowTimestamp } from "../../utils/utils";
import { setMeetingName, setMeetingCreating } from '../../actions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

const THANK_YOU_PAGE = "https://immersively.care/session-thankyou";

const VideoHome = (props) => {
   
   const history = useNavigate();
   const { meetingArgs: { topic }} = props;
   const [searchParams] = useSearchParams();
   const [meeting, setMeeting] = useState(null);
   const [showModal, setShowModal] = useState(false);
   const [urlTopic] = useState(searchParams.get("q"));

   useEffect(() => {
      props.setMeetingCreating(false);
   }, []);

   useEffect(() => {
      if(urlTopic) {
         getMeeting(urlTopic);
      }
   }, [urlTopic]);

   useEffect(() => {
      if(meeting) {
         setShowModal(true);
      }
   }, [meeting]);

   useEffect(() => {
      if(props.authUser) {
         props.setMeetingName(props.authUser.fullName);
      }
   }, [props.authUser]);

   const getSignature = (name, e) => {
      if (!name) {
         alert('Please enter valid informations');
         return;
      }
      setShowModal(false);
      e?.preventDefault();
      history(`${Urls.VIDEO.CALL}${topic ? '?q='+topic : ''}`);
   }

   const getMeeting = (urlTopic) => {
      MeetingService.getMeetingByTopic(urlTopic).then((response) => {
        if (response.endAt < nowTimestamp()) {
            alert("Meeting was scheduled for "+ formatDate(response.startAt));
        } else {
            setMeeting(response);
        }
      }).catch(async err => {
        console.log(err);
      });
    }

   const onUpdateName = (name) => {
      props.setMeetingName(name);
   }

   return (
      <>
         <PageHelmet pageTitle="Session" />
         {/* <HomeOneHeader /> */}
         {/* <CommonPageHeader title="Video Call" subtitle="Call" /> */}
         <section className="about__area pb-200 pt-100" style={{ display: 'flex', justifyContent: 'center' }}>
            <img src="https://immersively.care/wp-content/uploads/2022/05/main-logo@1x.png" />
            {/* <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/about/about-1.jpg" alt="" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>Video meeting</span>
                           <h2>Call a Facilitator</h2>
                        </div>
                        <p>Our Virtual Meeting Platform empowers online therapy or coaching sessions with real-time biomarkers captured using your webcam and a browser only.</p>
                        <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck /> </i>Multiple participants</span></li>
                              <li ><span><i > <FaCheck /> </i>Professional assistance</span></li>
                              <li ><span><i > <FaCheck /> </i>HD video quality</span></li>
                           </ul>
                        </div>
                        {
                           props.authUser ? 
                              <Link to='#' 
                                 onClick={(e) => {
                                    if(props.authUser) {
                                          props.setMeetingCreating(true);
                                          getSignature(props.authUser.fullName, e);
                                       } else {
                                          setShowModal(true);
                                       }
                                    }
                                 } 
                                 className="z-btn"
                              >
                                 Start a session
                              </Link>
                           :
                              <Link to={Urls.SIGN_IN} className="z-btn" >Start a session</Link>  
                           }
                     </div>
                  </div>
               </div>
            </div> */}
         </section>
         <ParticipantInfoModal
            visible={showModal}
            authUser={props.authUser}
            fullName={props.authUser?.fullName}
            onclose={() => {
               window.location.href = props.authUser ? Urls.DASHBOARD.SESSIONS : THANK_YOU_PAGE;
            }}
            onCancel={() => {
               window.location.href = props.authUser ? Urls.DASHBOARD.SESSIONS : THANK_YOU_PAGE;
               setShowModal(false);
            }}
            onUpdateName={(name) => onUpdateName(name)}
            onOk={(name, e) => getSignature(name, e)}
         />
         {/* <HomeFooter /> */}
      </>
   );
};

const mapStateToProps = ({ authUser }) => {
   return { authUser: authUser.data };
 };
 
 export default connect(mapStateToProps, { setMeetingName, setMeetingCreating })(VideoHome);