 import {
    SET_AUTH_USER,
    SET_AUTH_USER_SUCCESS,
    SET_AUTH_USER_FAILURE,
} from './types';
import * as Routes from "../services/sessions/routes";

import fetch from '../services/FetchInterceptor';

export const setAuthUser = () => (dispatch) => {
    dispatch({ type: SET_AUTH_USER });
    return fetch({
            url: Routes.KYC,
            method: 'get'
        }).then((response) => {
            localStorage.setItem('USER_REFERENCE', response.userReference);
            dispatch({ type: SET_AUTH_USER_SUCCESS, payload: response });
            return Promise.resolve();
        }).catch((error) => {
            console.error(error);
            dispatch({ type: SET_AUTH_USER_FAILURE });
            return Promise.reject();
        });
};

