import './recording.scss';
import classNames from 'classnames';
import { Button, Modal, Tooltip } from "antd";
import ZoomContext from '../../../context/zoom-context';
import { useState, useEffect, useContext } from "react";
import { IconFont } from '../../../components/icon-font';

const RecordingButton = (props: any) => {
  
  const zmClient = useContext(ZoomContext);
  const [record, setRecord] = useState(false);
  // const [agreementVisible, setAgreementVisible] = useState(false);
  // const [permissionVisible, setPermissionVisible] = useState(false);

  useEffect(() => {
    props.sendRecordRequest(record && props.status && props.status !== "recording" && !props.mediaBlobUrl);
    // setRecord(false);
    console.log("recording ", props.isRecording);
    
  }, [record]);

  const sendNotificationToClient = () => {
    setRecord(true);
    // setAgreementVisible(true);
  }

  const stopRecording = () => {
    setRecord(false);
  }
  
  return (
    <>
      { zmClient.isHost() && ( 
        <div>
          <Tooltip title={`Record meeting`}>          
          { props.status && props.status !== "recording" && !props.mediaBlobUrl ? (
            <Button
              className={classNames('recording-operation-button', {
              })}
              id="start-recording-id"
              icon={<IconFont type={ 'icon-recording-hover'} />}
              // eslint-disable-next-line react/jsx-boolean-value
              ghost={true}
              shape="circle"
              size="large"
              onClick={() => sendNotificationToClient()}
            />
          ) :  (
            <Button
              className={classNames('recording-operation-button', {
              })}
              icon={<IconFont type={'icon-recording-stop-hover'} />}
              // eslint-disable-next-line react/jsx-boolean-value
              ghost={true}
              shape="circle"
              size="large"
              style={{ backgroundColor: 'red' }}
              onClick={async () => { 
                console.log("Je viens de cliquer 1")
                await props.stopRecording(); 
                stopRecording();
              }}
            />
          )}
          <Button
              // eslint-disable-next-line react/jsx-boolean-value
              ghost={true}
              id="stop-recording-id"
              style={{ backgroundColor: 'red', display: 'none' }}
              onClick={async () => { 
                console.log("Je viens de cliquer 2")
                await props.stopRecording(); 
                stopRecording();
              }}
            />
            </Tooltip>
            <div style={{
                      color: "white",
                      fontSize: "12px",
                      textAlign: "center",
                      marginLeft: "30px"
                    }}>
              <span>Recording</span>
            </div>
        </div>
      )}
      { !zmClient.isHost() && props.isRecording && (
        <div>
          <Button
          className={classNames('recording-operation-button', {
          })}
            icon={<IconFont type={'icon-recording-hover'} />}
            // eslint-disable-next-line react/jsx-boolean-value
            ghost={true}
            shape="circle"
            size="large"
            style={{ backgroundColor: 'red' }}
            // onClick={() => props.stopRecording()}
          />
          <div style={{
                    color: "white",
                    fontSize: "12px",
                    textAlign: "center",
                    marginLeft: "30px"
                  }}>
            <span>Recording</span>
          </div>
        </div>
      )}
    </>
  )
};

export default RecordingButton;
