/* eslint-disable no-nested-ternary */
import 'antd/dist/antd.css';
import "./invitation.scss";
import validator from 'validator';
import classNames from "classnames";
import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { UserAddOutlined } from '@ant-design/icons';
import SessionService from "../../../services/sessions/index";
import { Tooltip, Button, Form, Card, Input, message } from "antd";
import { BiInfoCircle } from 'react-icons/bi';

interface InvitationButtonProps {
  className?: string;
  meetingArgs?: {
    signature: string;
    topic: string;
    name: string;
    password?: string;
  };
}
const InvitationButton = (props: InvitationButtonProps) => {

  
  const {
    className,
  } = props;
  
  const rules = {
    email: [
      {
        required: true,
        message: "Please enter the email address",
      }
    ]
  }
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('')
  const [color, setColor] = useState('')

  const meetingLink = window.location.origin + '/app/session-start?q=' + props.meetingArgs?.topic

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const onCopyLink = () => {
    navigator.clipboard.writeText(meetingLink);
    message.success("Link copied");
  }

  const handleCancel = () => {
    setVisible(false);
    // getChats();
  };

  const onFinish = () => {
    form.validateFields().then(values => {
      let datas = {
        email: values.email,
        message: "Hello,\n\n you have been invited to join an Immersively Care meeting.\n\nClick on the secured meeting link below to join:\n\n" + meetingLink + "\n\n"
      }
      //console.log(datas);
      setLoading(true);
      SessionService.inviteUsers(datas).then(() => {
        message.success("Invitation link sent successfully");
        handleCancel();
      }).catch(err => {
        message.error("Sending invitation link failed");
        handleCancel();
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  }

  const validateEmail = (e: any) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('Valid Email')
      setColor('green');
    } else {
      setEmailError('Enter valid Email!')
      setColor('red');
    }
  }

  return (
    <div className={classNames("invitation-footer", className)}>
      <Modal
        visible={visible}
        onOk={() => handleOk()}
        title="Invite participant to join"
        onCancel={() => handleCancel()}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Cancel
          </Button>,
          <Button key="submit" type='primary' loading={loading} onClick={() => onFinish()}>
            Send invitation email
          </Button>,
        ]}
      >
        <div>
          <div className='d-flex justify-content-between'>
            <p><BiInfoCircle size={20} color='green'/> Copy and share session link</p>
            <p style={{ color: 'green', padding: 3, cursor: 'pointer' }} onClick={() => onCopyLink()}>Copy link</p>
          </div>
          {meetingLink}
        </div>

        <Form
          form={form}
          layout="vertical"
          name="profile_general"
          className="ant-advanced-search-form"
        >
            <Form.Item className='mt-3' name="email" label="Enter the guest's email" rules={rules.email}>
              <Input placeholder="email address" onChange={(e) => validateEmail(e)} />
            </Form.Item>
        </Form>
      </Modal>
      <Tooltip title="add participant">
        <Button
          className={"invitation-button bg-transparent"}
          icon={<UserAddOutlined />}
          size="large"
          ghost
          shape="circle"
          onClick={() => showModal()}
        />
      </Tooltip>
      <div style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                marginLeft: "30px"
              }}>
        <span>Invite guest</span>
      </div>
    </div>
  );
};

export default InvitationButton;