import './chat.scss';
import 'antd/dist/antd.css'
import produce from 'immer';
import classNames from 'classnames';
import { useMount } from '../../../hooks';
import { ChatPrivilege } from '@zoom/videosdk';
import { MessageOutlined } from '@ant-design/icons';
import { Tooltip, Button, Drawer, Input  } from 'antd';
import ChatContext from '../../../context/chat-context';
import ZoomContext from '../../../context/zoom-context';
import { ChatReceiver, ChatRecord } from './chats/chat-types';
import ChatMessageItem from './chats/component/chat-message-item';
import { useParticipantsChange } from './chats/hooks/useParticipantsChange';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';


const { TextArea } = Input;

const ChatButton = (props: any) => {
  const { className } = props;
  const [visible, setVisible] = useState(false);

  const zmClient = useContext(ZoomContext);
  const chatClient = useContext(ChatContext);
  const [chatRecords, setChatRecords] = useState<ChatRecord[]>([]);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [chatReceivers, setChatReceivers] = useState<ChatReceiver[]>([]);
  const [chatUser, setChatUser] = useState<ChatReceiver | null>(null);
  const [chatDraft, setChatDraft] = useState<string>('');
  const chatWrapRef = useRef<HTMLDivElement | null>(null);
  const onChatMessage = useCallback(
    (payload: ChatRecord) => {
      setChatRecords(
        produce((records: ChatRecord[]) => {
          const length = records.length;
          if (length > 0) {
            const lastRecord = records[length - 1];
            if (
              payload.sender.userId === lastRecord.sender.userId &&
              payload.receiver.userId === lastRecord.receiver.userId &&
              payload.timestamp - lastRecord.timestamp < 1000 * 60 * 5
            ) {
              if (Array.isArray(lastRecord.message)) {
                lastRecord.message.push(payload.message as string);
              } else {
                lastRecord.message = [lastRecord.message, payload.message as string];
              }
            } else {
              records.push(payload);
            }
          } else {
            records.push(payload);
          }
        }),
      );
      if (chatWrapRef.current) {
        chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
      }
    },
    [chatWrapRef],
  );

  const onChatInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setChatDraft(event.target.value);
    },
    [],
  );

  useEffect(() => {
    zmClient.on('chat-on-message', onChatMessage);
    return () => {
      zmClient.off('chat-on-message', onChatMessage);
    };
  }, [zmClient, onChatMessage]);

  useParticipantsChange(zmClient, () => {
    if (chatClient) {
      setChatReceivers(chatClient.getReceivers());
    }
  });
  useEffect(() => {
    if (chatUser) {
      const index = chatReceivers.findIndex(
        (user) => user.userId === chatUser.userId,
      );
      if (index === -1) {
        setChatUser(chatReceivers[0]);
      }
    } else {
      if (chatReceivers.length > 0) {
        setChatUser(chatReceivers[0]);
      }
    }
  }, [chatReceivers, chatUser]);
  const setChatUserId = useCallback(
    (userId) => {
      const user = chatReceivers.find((u) => u.userId === userId);
      if (user) {
        setChatUser(user);
      }
    },
    [chatReceivers],
  );

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const sendMessage = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      if (chatUser && chatDraft) {
        chatClient?.send(chatDraft, chatUser?.userId);
        setChatDraft('');
      }
    },
    [chatClient, chatDraft, chatUser],
  );
  useMount(() => {
    setCurrentUserId(zmClient.getSessionInfo().userId);
  });

  return (
    <div className={classNames('chat-footer', className)}>
      <Tooltip title={`chat with other participant`}>
        <Button
          className={classNames('chat-button', className)}
          icon={
            <MessageOutlined />
          }
          ghost={true}
          shape="circle"
          size="large"
          onClick={() => {
            if(visible) {
              onClose();
            } else {
              showDrawer();
            }
          }}
        />
      </Tooltip>
      <Drawer 
        title="Meeting chat" 
        placement="left" 
        mask={false}
        onClose={onClose} visible={visible}
        footer={<div className="chat-message-box">
          <TextArea
            onPressEnter={sendMessage}
            onChange={onChatInput}
            value={chatDraft}
            style={{ borderColor: 'black' }}
            placeholder="Type message here ..."
          />
        </div>
        }
      >
        <div className="chat-message-wrap" ref={chatWrapRef}>
          {chatRecords.map((record) => (
            <ChatMessageItem
              record={record}
              currentUserId={currentUserId}
              setChatUser={setChatUserId}
              key={record.timestamp}
            />
          ))}
        </div>
      </Drawer>
      <div style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                marginLeft: "30px"
              }}>
        <span>Chat</span>
      </div>
    </div>
  );
};
export default ChatButton;
