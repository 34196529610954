export const SET_AUTH_USER = 'SET_AUTH_USER';
export const CLEAR_AUTH_USER = 'CLEAR_AUTH_USER';
export const SET_MEETING_NAME = 'SET_MEETING_NAME';
export const SET_MEETING_SIGNALS = 'SET_MEETING_SIGNALS';
export const SET_MEETING_TRIGGER = 'SET_MEETING_TRIGGER';
export const SET_MEETING_SHARING = 'SET_MEETING_SHARING';
export const SET_MEETING_CREATING = 'SET_MEETING_CREATING';
export const SET_AUTH_USER_FAILURE = 'SET_AUTH_USER_FAILURE';
export const SET_MEETING_TRACKINGS = 'SET_MEETING_TRACKINGS';
export const SET_AUTH_USER_SUCCESS = 'SET_AUTH_USER_SUCCESS';
export const SET_MEETING_OTHERS_SIGNALS = 'SET_MEETING_OTHERS_SIGNALS';
export const SET_MEETING_CLIENT_TRACKINGS = 'SET_MEETING_CLIENT_TRACKINGS';